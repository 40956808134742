<template>
  <MainLayout activeItem="Streams">
    <div class="flex-1 overflow-hidden flex flex-col">
      <div class="mt-4 pl-8 pr-8 pb-4 border-b flex items-center justify-between">
        <h1 class="text-3xl font-extrabold text-gray-900">Streams</h1>
        <label class="text-xs flex items-center gap-1 cursor-pointer">
          <input
            type="checkbox"
            :checked="shouldShowOnlyRtp"
            class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-0"
            :style="{ boxShadow: 'none' }"
            @click="handleRtpCheckboxClick"
          />
          Blur
        </label>
      </div>
      <LoadingSection v-if="loading" :loading="true" class="flex-1" />
      <div
        v-if="!loading"
        class="overflow-auto flex-1 border-b border-gray-200 shadow sm:rounded-lg bg-white"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="pl-6 pr-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                #
              </th>
              <th
                scope="col"
                class="pl-3 pr-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Status
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Last Update
              </th>
              <th
                v-if="shouldShowOnlyRtp"
                scope="col"
                class="pl-3 pr-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Blur
              </th>
              <th
                v-if="shouldShowOnlyRtp"
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Blur Update
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Project Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Customer
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Displayed Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                AWS Stream ID
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Actions
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 whitespace-nowrap"
              >
                Last reboot result
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 whitespace-nowrap"
              >
                Last reboot date
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="(stream, index) in finalStreams" :key="stream._id">
              <td class="pl-6 pr-3 py-4 text-sm font-medium text-gray-500">
                {{ index + 1 }}
              </td>
              <td class="flex whitespace-nowrap pl-3 pr-6 py-4 text-sm font-medium text-gray-500">
                <XCircleIcon
                  class="-ml-1 mr-3 h-5 w-5 text-red-400"
                  aria-hidden="true"
                  v-if="stream.status === 'offline'"
                />
                <CheckCircleIcon
                  class="-ml-1 mr-3 h-5 w-5 text-green-400"
                  aria-hidden="true"
                  v-if="stream.status === 'online'"
                />
                {{ stream.status }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 align-top">
                {{ formatDate(stream.status_updated_at) }}
              </td>
              <td
                v-if="shouldShowOnlyRtp"
                class="flex whitespace-nowrap pl-3 pr-6 py-4 text-sm font-medium text-gray-500"
              >
                <XCircleIcon
                  class="-ml-1 mr-3 h-5 w-5 text-red-400"
                  aria-hidden="true"
                  v-if="stream.rtp_status === 'offline'"
                />
                <CheckCircleIcon
                  class="-ml-1 mr-3 h-5 w-5 text-green-400"
                  aria-hidden="true"
                  v-if="stream.rtp_status === 'online'"
                />
                {{ stream.rtp_status }}
              </td>
              <td
                v-if="shouldShowOnlyRtp"
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 align-top"
              >
                {{ formatDate(stream.rtp_status_updated_at) }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 align-top">
                <a
                  :href="`https://app.oculai.de/${stream.customer_name}/${stream.site_id}/dashboard`"
                >
                  {{ projectNameMap[`${stream.customer_name}_${stream.site_id}`] }}
                </a>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 align-top">
                <router-link
                  :to="{
                    name: 'ProjectConsole',
                    query: {
                      customer_name: stream.customer_name,
                      site_id: stream.site_id,
                    },
                  }"
                >
                  {{ stream.customer_name }}_{{ stream.site_id }}
                </router-link>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 align-top">
                <a
                  :href="`https://app.oculai.de/${stream.customer_name}/${stream.site_id}/${stream.camera_id}/live`"
                >
                  {{ stream.name }}
                </a>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 align-top">
                <router-link
                  :to="{
                    name: 'ProjectConsole',
                    query: {
                      customer_name: stream.customer_name,
                      site_id: stream.site_id,
                      camera_id: stream.camera_id,
                      tab: 'streams',
                    },
                  }"
                >
                  {{ stream.aws_stream_id }}
                </router-link>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 align-top">
                <span
                  class="cursor-pointer text-blue-700 hover:text-blue-800"
                  @click="rebootStream(stream)"
                  v-if="!rebootingStreamStatuses[stream._id]"
                >
                  reboot
                </span>
                <span v-if="rebootingStreamStatuses[stream._id]" class="text-orange-500">
                  rebooting...
                  <vue-countdown :time="100000" v-slot="{ minutes, seconds }">
                    {{ formatTime(minutes, seconds) }}
                  </vue-countdown>
                </span>
                <span> | </span>
                <span
                  class="cursor-pointer text-blue-700 hover:text-blue-800"
                  @click="setStatus(stream, 'online')"
                  v-if="stream.status !== 'online'"
                >
                  mark online
                </span>
                <span
                  class="cursor-pointer text-blue-700 hover:text-blue-800"
                  @click="setStatus(stream, 'offline')"
                  v-if="stream.status && stream.status !== 'offline'"
                >
                  mark offline
                </span>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 align-top">
                <div
                  class="flex gap-1 text-green-400"
                  v-if="getLatestRebootResult(stream)?.rebooted"
                >
                  <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                  Stream successfully rebooted
                </div>
                <div
                  v-else-if="getLatestRebootResult(stream)?.reason"
                  class="flex gap-1 text-red-500"
                >
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                  {{ getLatestRebootResult(stream).reason }}
                </div>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 align-top">
                {{ formatDate(getLatestRebootResult(stream)?.created) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </MainLayout>
</template>

<script lang="ts">
import VueCountdown from "@chenfengyuan/vue-countdown";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/solid";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { defineComponent } from "vue";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import StreamRepository from "shared/repositories/StreamRepository";
import logger from "shared/services/logger";
import { FullStreamRebootResult, Stream, StreamRebootResult } from "shared/types/Stream";
import MainLayout from "@/components/layout/MainLayout.vue";
import ProjectRepository from "@/repositories/ProjectRepository";

export default defineComponent({
  name: "Streams",
  data() {
    return {
      streams: [] as Stream[],
      loading: false as boolean,
      rebootingStreamStatuses: {} as Record<string, boolean>,
      projectNameMap: {} as Record<string, string>,
      latestRebootResults: [] as FullStreamRebootResult[],
      isSettingStatus: false,
    };
  },
  async mounted() {
    this.loading = true;
    try {
      await Promise.all([this.loadProjects(), this.loadActiveStreams()]);
      await this.loadLatestRebootResults();
    } finally {
      this.loading = false;
    }
  },
  components: {
    MainLayout,
    LoadingSection,
    CheckCircleIcon,
    XCircleIcon,
    VueCountdown,
  },
  computed: {
    latestRebootResultsMap() {
      return this.latestRebootResults.reduce((acc, streamRebootResult) => {
        acc[this.getKey(streamRebootResult)] = streamRebootResult;
        return acc;
      }, {} as Record<string, FullStreamRebootResult>);
    },
    shouldShowOnlyRtp() {
      return this.$route.query.rtp === "true";
    },
    finalStreams() {
      if (this.shouldShowOnlyRtp) {
        return this.streams.filter((stream) => stream.rtp);
      }
      return this.streams;
    },
  },
  methods: {
    loadProjects() {
      return ProjectRepository.loadProjects()
        .then((projects) => {
          this.projectNameMap = projects.reduce((acc, project) => {
            acc[`${project.customer_name}_${project.site_id}`] = project.name;
            return acc;
          }, {} as Record<string, string>);
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    async loadActiveStreams() {
      try {
        this.streams = await StreamRepository.loadActiveStreams();
        const unknownStatusStreams = this.streams.filter(
          (stream) => !stream.status || !stream.status_updated_at,
        );
        const knownStatusStreams = this.streams.filter(
          (stream) => stream.status && stream.status_updated_at,
        );
        knownStatusStreams.sort((a, b) => {
          if (a.status === b.status) {
            return (a.status_updated_at as Date) > (b.status_updated_at as Date) ? -1 : 1;
          }
          return (a.status as string).localeCompare(b.status as string);
        });
        this.streams = [...unknownStatusStreams, ...knownStatusStreams];
      } catch (error) {
        logger.error(error);
      }
    },
    async loadLatestRebootResults() {
      try {
        const latestRebootResults = await StreamRepository.loadLatestRebootResults();

        const streamMap = this.streams.reduce((acc, stream) => {
          acc[this.getKey(stream)] = stream;
          return acc;
        }, {} as Record<string, Stream>);

        this.latestRebootResults = latestRebootResults.filter((rebootResult) => {
          const stream = streamMap[this.getKey(rebootResult)];
          return stream?.status !== "online";
        });
      } catch (error) {
        logger.error(error);
      }
    },
    getErrorMessage(error: Error) {
      if (error instanceof AxiosError && error.response?.data?.detail) {
        return error.response.data.detail;
      }
      return error.message || "Unknown error";
    },
    formatTime(minutes: number, seconds: number) {
      return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
    async rebootStream(stream: Stream) {
      if (!window.confirm(`Do you really want to reboot stream ${stream.aws_stream_id}?`)) {
        return;
      }
      this.rebootingStreamStatuses[stream._id] = true;
      try {
        const result = await StreamRepository.rebootStream(
          stream.customer_name,
          stream.site_id,
          stream.camera_id,
        );
        this.updateLatestRebootResult(stream, result);
      } catch (error) {
        logger.error("Unable to reboot stream", error as Error);
        this.updateLatestRebootResult(stream, {
          rebooted: false,
          reason: this.getErrorMessage(error as Error),
        });
      } finally {
        this.rebootingStreamStatuses[stream._id] = false;
      }
    },
    formatDate(date: Date | null) {
      return date ? format(date, "dd.MM.yyyy HH:mm") : "";
    },
    getLatestRebootResult(stream: Stream) {
      return this.latestRebootResultsMap[
        [stream.customer_name, stream.site_id, stream.camera_id, stream.aws_stream_id].join("_")
      ];
    },
    updateLatestRebootResult(stream: Stream, result: StreamRebootResult) {
      const streamRebootResult = this.latestRebootResults.find(
        ({ customer_name, site_id, camera_id, aws_stream_id }) =>
          customer_name === stream.customer_name &&
          site_id === stream.site_id &&
          camera_id === stream.camera_id &&
          aws_stream_id === stream.aws_stream_id,
      );

      if (streamRebootResult) {
        streamRebootResult.reason = result.reason;
        streamRebootResult.rebooted = result.rebooted;
        streamRebootResult.created = new Date();
      } else {
        this.latestRebootResults.push({
          _id: new Date().getTime().toString(),
          customer_name: stream.customer_name,
          site_id: stream.site_id,
          camera_id: stream.camera_id,
          aws_stream_id: stream.aws_stream_id as string,
          reason: result.reason,
          rebooted: result.rebooted,
          created: new Date(),
        });
        return;
      }
    },
    getKey({
      customer_name,
      site_id,
      camera_id,
      aws_stream_id,
    }: {
      customer_name: string;
      site_id: string;
      camera_id: string;
      aws_stream_id: string | null;
    }) {
      return [customer_name, site_id, camera_id, aws_stream_id].join("_");
    },
    setStatus(stream: Stream, status: Stream["status"]) {
      if (this.isSettingStatus) {
        return;
      }
      this.isSettingStatus = true;
      StreamRepository.setStatus(stream.customer_name, stream.site_id, stream.camera_id, status)
        .then((updatedStream) => {
          const index = this.streams.findIndex((item) => item === stream);
          if (index > -1) {
            this.streams.splice(index, 1, updatedStream);
            this.$store.commit(
              "setOfflineStreamCount",
              this.streams.filter((s) => s.status === "offline").length,
            );
          }
        })
        .catch((error) => {
          logger.error(error);
          alert("Unable to set status");
        })
        .finally(() => {
          this.isSettingStatus = false;
        });
    },
    handleRtpCheckboxClick() {
      this.$router.replace({
        query: { ...this.$route.query, rtp: this.shouldShowOnlyRtp ? undefined : "true" },
      });
    },
  },
});
</script>
