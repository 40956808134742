<template>
  <MainLayout :activeItem="'Metrics'">
    <Modal :open="open" @close="open = false">
      <template #content>
        <div :id="singlePlot"></div>
      </template>
    </Modal>
    <div class="divide-gray-200 pb-6 flex flex-col flex-1">
      <div class="py-4 flex justify-between items-center bg-gray-50 px-4 sm:px-6">
        <h1 class="text-3xl font-extrabold text-gray-900">Metrics</h1>
      </div>
      <div class="flex justify-between py-2 px-4 sm:px-6 border-b bg-white top-0 z-10">
        <div class="flex">
          <div :class="[dailyMetricsMode ? 'grid-cols-4' : 'grid-cols-6', 'grid gap-4']">
            <div class="col-span-1 items-center">
              <label
                for="customer_name"
                class="block text-sm font-medium leading-6 text-gray-900 row-span-1"
                >Customer Name</label
              >
              <div class="row-span-1">
                <SearchList
                  :defaultOptions="customers"
                  editMode="true"
                  placeholder="All customers"
                  :nullable="true"
                  type="customers"
                  :selectedValue="selectedCustomer"
                  @updateEvent="updateEvent('customer', $event)"
                />
              </div>
              <small
                class="text-red row-span-1"
                v-if="error && dailyMetricsMode && selectedCustomer === ''"
                >Customer Name required</small
              >
            </div>
            <div class="col-span-1 items-center">
              <label
                for="site_id"
                class="block text-sm font-medium leading-6 text-gray-900 row-span-1"
                >Site ID</label
              >
              <div class="row-span-1">
                <SearchList
                  :defaultOptions="sites"
                  :edit-mode="selectedCustomer"
                  :selectedValue="selectedSite"
                  :nullable="true"
                  type="sites"
                  @updateEvent="updateEvent('site', $event)"
                  placeholder="All sites"
                />
              </div>

              <small
                class="text-red row-span-1"
                v-if="error && dailyMetricsMode && selectedSite === ''"
                >Site Id required</small
              >
            </div>
            <div class="col-span-1 items-center">
              <label
                for="camera_id"
                class="block text-sm font-medium leading-6 text-gray-900 row-span-1"
                >Camera ID</label
              >
              <div class="row-span-1">
                <SearchList
                  :defaultOptions="cameras"
                  :edit-mode="selectedSite"
                  :selectedValue="selectedCamera"
                  :nullable="true"
                  type="cameras"
                  @updateEvent="updateEvent('camera', $event)"
                  placeholder="All cameras"
                />
              </div>
              <small
                class="text-red row-span-1"
                v-if="error && dailyMetricsMode && selectedCamera === ''"
                >Camera Id required</small
              >
            </div>
            <div class="col-span-1 items-center">
              <label
                for="start_time"
                class="block text-sm font-medium leading-6 text-gray-900 row-span-1"
                >{{ dailyMetricsMode ? "Date" : "Start Date" }}</label
              >
              <input
                type="date"
                max="9999-12-12T00:00:00.00"
                id="startDate"
                v-model="selectedStart"
                class="form-control block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2 row-span-1"
              />
              <small class="text-red row-span-1" v-if="error && selectedStart === ''">{{
                dailyMetricsMode ? "Date required" : "Start Date required"
              }}</small>
            </div>
            <div class="col-span-1 items-center" v-if="!dailyMetricsMode">
              <label
                for="end_time"
                class="block text-sm font-medium leading-6 text-gray-900 row-span-1"
                >End Date</label
              >
              <input
                type="date"
                max="9999-12-12T00:00:00.00"
                id="endDate"
                v-model="selectedEnd"
                :disabled="selectedStart === ''"
                class="form-control block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2 row-span-1"
              />
              <small class="text-red row-span-1" v-if="error && selectedEnd === ''"
                >End date required</small
              >
              <small class="text-red row-span-1" v-if="error && compareDates"
                >Start date larger end date</small
              >
            </div>
            <div class="col-span-1 items-center pl-2" v-if="!dailyMetricsMode">
              <label
                for="0_process_filter"
                class="block text-sm font-medium leading-6 text-gray-900 row-span-1"
                >0-Process Filter</label
              >
              <div class="py-2">
                <Switch
                  v-model="zeroProcessFilter"
                  :class="[
                    zeroProcessFilter ? 'bg-green-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      zeroProcessFilter ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </div>
            </div>
          </div>
          <div class="ml-3 pt-6">
            <div class="row-start-2 row-end-3 flex items-center h-9">
              <button
                @click="sendReq()"
                class="rounded-md border border-transparent bg-orange px-6 py-2 text-sm font-medium text-white hover:bg-orange-600"
                :class="{ 'button-disabled': disableSend }"
                :disabled="disableSend"
              >
                Send
              </button>
              <button
                type="button"
                class="pl-2 ml-2 inline-flex rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white hover:bg-gray-600"
                @click="resetFilters"
              >
                <ArrowUturnLeftIcon class="h-5 w-5 text-white" aria-hidden="true" />
                <span class="pl-2">Reset</span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <Switch
            v-model="dailyMetricsMode"
            :class="[
              dailyMetricsMode ? 'bg-green-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2',
            ]"
          >
            <span
              aria-hidden="true"
              :class="[
                dailyMetricsMode ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              ]"
            />
          </Switch>
          <span class="block text-sm font-medium leading-6 text-gray-900 pl-2">Daily View</span>
        </div>
      </div>
      <LoadingSection :loading="loading" class="flex-1" />
      <div class="min-h-max px-4 sm:px-6" v-if="!loading">
        <div class="text-red px-4 sm:px-6 col-span-5 text-center my-5" v-if="reqErr">
          <p>{{ errorMsg }}</p>
        </div>
        <div class="grid grid-cols-3 gap-6 my-5" v-if="dailyMetricsMode">
          <div class="col-span-2 borderedSection" v-if="!reqErr && dailyPlots">
            <div id="plot_daily_results_3D" ref="plot_daily_results_3D" />
          </div>
          <div class="col-span-1 borderedSection" v-if="!reqErr && dailyPlots">
            <div id="plot_daily_interpretability" ref="plot_daily_interpretability" />
          </div>
          <div class="col-span-3" v-show="table">
            <TableMetrics :table="table" />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-3 mt-5" v-else>
          <template v-for="(value, key) in rangePlots" :key="value">
            <div v-if="!reqErr && rangePlots" :class="{ 'borderedSection border-t': !loading }">
              <div class="col-span-1 rounded-lg" :id="key" :ref="key" />
              <div class="flex justify-center">
                <button
                  @click="openModal(key, value)"
                  class="rounded-md border text-center border-transparent bg-orange px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-600"
                >
                  See more
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script lang="ts">
import { Switch } from "@headlessui/vue";
import { ArrowUturnLeftIcon } from "@heroicons/vue/20/solid";
import { format, isBefore, subMonths } from "date-fns";
import { defineComponent } from "vue";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import Modal from "shared/components/modals/Modal.vue";
import StreamRepository from "shared/repositories/StreamRepository";
import logger from "shared/services/logger";
import { Stream } from "shared/types/Stream";
import MainLayout from "@/components/layout/MainLayout.vue";
import SearchList from "@/components/other/SearchList.vue";
import PipelineMetricsRepository from "@/repositories/PipelineMetricsRepository";
import TableMetrics from "@/views/metrics/components/TableMetrics.vue";
import { RangePipelineMetricsPlots, DailyPipelineMetricsPlots } from "@/views/metrics/types";

let Plotly: typeof import("plotly.js-dist-min") | null = null;

export default defineComponent({
  name: "MetricsFilter",
  components: {
    SearchList,
    MainLayout,
    Modal,
    ArrowUturnLeftIcon,
    TableMetrics,
    Switch,
    LoadingSection,
  },

  data() {
    return {
      streams: [] as Stream[],
      selectedCustomer: "" as string,
      selectedSite: "" as string,
      selectedCamera: "" as string,
      selectedStart: "" as string,
      selectedEnd: "" as string,
      rangePlots: null as RangePipelineMetricsPlots | null,
      dailyPlots: null as DailyPipelineMetricsPlots | null,
      table: null as Record<string, number> | null,
      singlePlot: "" as string,
      open: false as boolean,
      loading: false as boolean,
      disableSend: false as boolean,
      error: false as boolean,
      reqErr: false as boolean,
      errorMsg: "" as string,
      zeroProcessFilter: false as boolean,
      dailyMetricsMode: false as boolean,
    };
  },
  async mounted() {
    this.loading = true;
    this.initializeStartAndEndDate();
    await Promise.all([this.loadPlotly(), this.getFilterOptions(), this.getPlots()]);
    if (this.rangePlots) {
      this.showPlots(this.rangePlots);
    }
  },
  watch: {
    dailyMetricsMode(newValue) {
      this.error = false;
      this.reqErr = false;
      this.resetData();
      if (!newValue) {
        this.initializeStartAndEndDate();
        this.getPlots();
      }
    },
  },
  computed: {
    compareDates(): boolean {
      return isBefore(new Date(this.selectedEnd), new Date(this.selectedStart));
    },
    customers(): string[] {
      const customers = [...new Set(this.streams.map((stream) => stream.customer_name))];
      customers.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      return customers;
    },
    sites(): string[] {
      const sites = [
        ...new Set(
          this.streams
            .filter((stream) => stream.customer_name === this.selectedCustomer)
            .map((stream) => stream.site_id),
        ),
      ];
      sites.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      return sites;
    },
    cameras(): string[] {
      const cameras = this.streams
        .filter((stream) => stream.customer_name === this.selectedCustomer)
        .filter((stream) => stream.site_id === this.selectedSite)
        .map((stream) => stream.camera_id);
      cameras.sort();
      return cameras;
    },
  },

  methods: {
    initializeStartAndEndDate() {
      this.selectedStart = format(subMonths(new Date(), 1), "yyyy-MM-dd") as string;
      this.selectedEnd = format(new Date(), "yyyy-MM-dd") as string;
    },
    async loadPlotly() {
      Plotly = await import("plotly.js-dist-min").catch((error) => {
        logger.error(error);
        return null;
      });
    },
    sendReq() {
      this.reqErr = false;
      this.error = false;

      if (
        (!this.dailyMetricsMode && (this.selectedStart === "" || this.compareDates)) ||
        (this.dailyMetricsMode &&
          (this.selectedCustomer === "" ||
            this.selectedSite === "" ||
            this.selectedCamera === "" ||
            this.selectedStart === ""))
      ) {
        this.error = true;
      } else {
        this.getPlots();
      }
    },
    resetData() {
      this.rangePlots = null;
      this.dailyPlots = null;
      this.table = null;
    },
    resetFilters() {
      this.resetData();
      this.error = false;
      this.reqErr = false;
      this.selectedCustomer = "";
      this.selectedSite = "";
      this.selectedCamera = "";
      this.zeroProcessFilter = false;

      if (this.dailyMetricsMode) {
        this.selectedStart = "";
      } else {
        this.initializeStartAndEndDate();
        this.resetData();
        this.getPlots();
      }
    },
    openModal(key: string, value: string) {
      this.$nextTick(() => {
        this.open = true;
        this.singlePlot = key + "#modal";
        const size = {
          w: 0.9,
          h: 0.9,
        };
        this.displayPlot(this.singlePlot, value, size);
      });
    },
    updateEvent(data: string, value: string) {
      if (data === "customer") {
        this.selectedCustomer = value == null ? "" : value;
      }
      if (data === "site") {
        this.selectedSite = value == null ? "" : value;
      }
      if (data === "camera") {
        this.selectedCamera = value == null ? "" : value;
      }
    },
    getFilterOptions() {
      return StreamRepository.loadActiveStreams()
        .then((streams) => {
          this.streams = streams;
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    getPlots() {
      this.loading = true;
      const customerName = this.selectedCustomer === "" ? "all" : this.selectedCustomer;
      const siteId = this.selectedSite === "" ? "all" : this.selectedSite;
      const cameraId = this.selectedCamera === "" ? "all" : this.selectedCamera;
      const startDate = this.selectedStart === "" ? "all" : this.selectedStart;
      const endDate = this.selectedEnd === "" ? startDate : this.selectedEnd;
      const filter = this.zeroProcessFilter ? "filter_0" : "no_filter";

      if (this.dailyMetricsMode) {
        return PipelineMetricsRepository.getPipelineMetricsDaily(
          customerName,
          siteId,
          cameraId,
          startDate,
          filter,
        )
          .then((response) => {
            this.dailyPlots = {
              plot_daily_results_3D: response.plot_daily_results_3D,
              plot_daily_interpretability: response.plot_daily_interpretability,
            };
            this.table = response.metrics.metrics_table;
            this.showPlots(this.dailyPlots);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.reqErr = true;
              this.errorMsg = "No data available for the selected options. Please try again";
            } else {
              logger.error(error);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        return PipelineMetricsRepository.getPipelineMetricsRange(
          customerName,
          siteId,
          cameraId,
          startDate,
          endDate,
          filter,
        )
          .then((response) => {
            this.rangePlots = response;
            this.showPlots(this.rangePlots);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.reqErr = true;
              this.errorMsg = "No data available for the selected options. Please try again";
            } else {
              logger.error(error);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    showPlots(plots: DailyPipelineMetricsPlots | RangePipelineMetricsPlots) {
      for (const [key, value] of Object.entries(plots)) {
        let size = {};
        if (!this.dailyMetricsMode) {
          size = {
            h: 0.5,
          };
        } else {
          size = {
            h: 0.6,
          };
        }
        this.displayPlot(key, value as string, size);
      }
    },
    displayPlot(key: string, value: string, size: Record<string, number>) {
      this.$nextTick(() => {
        const data = JSON.parse(value).data as Plotly.Data[];
        const layout = JSON.parse(value).layout as Partial<Plotly.Layout>;

        layout.width = document.documentElement.clientWidth * size.w;
        layout.height = document.documentElement.clientHeight * size.h;

        const graphDiv = document.getElementById(key) as HTMLDivElement | null;
        if (!graphDiv) {
          return;
        }

        const config = { responsive: true, displayModeBar: false } as Partial<Plotly.Config>;

        Plotly?.newPlot(graphDiv, data, layout, config);
      });
    },
  },
});
</script>

<style>
.button-disabled {
  @apply bg-orange-50;
}
</style>
