<template>
  <OnClickOutside
    @trigger="closeContextMenu"
    v-if="contextMenuState.objects.length > 0"
    :options="{ ignore: ['#popover'] }"
  >
    <div
      class="p-1 bg-white rounded text-sm flex flex-col min-w-44"
      :style="`position: fixed; top: ${contextMenuState.y}px; left: ${contextMenuState.x}px;`"
    >
      <Popover class="relative">
        <PopoverButton
          class="w-full outline-none flex justify-between items-center px-2 py-0.5 rounded hover:bg-gray-200 cursor-pointer"
        >
          <span>Add to group</span>
          <ChevronRightIcon class="w-4 h-4" />
        </PopoverButton>
        <OaiPopoverPanel position="right" class="z-[99]" childClass="flex-col">
          <ul
            class="overflow-auto focus text-xs px-2 py-1 basis-[150px] flex flex-col items-start bg-white ring-1 ring-gray-300 shadow-sm"
          >
            <li
              v-for="sequenceGroup in groupService.sequenceGroups"
              :key="sequenceGroup.group_id"
              class="relative text-xs cursor-pointer w-full select-none py-1 pl-2 pr-3 flex gap-2 hover:bg-gray-200"
              @click="addToGroup(sequenceGroup.group_id)"
            >
              <div
                class="w-1 h-4"
                :style="`background: ${
                  groupService.getProcessClassColor(sequenceGroup.process_class).main
                }`"
              />
              <span class="text-nowrap">
                #{{ sequenceGroup.group_id }} {{ sequenceGroup.process_class }}
              </span>
            </li>
          </ul>
        </OaiPopoverPanel>
      </Popover>

      <Popover class="relative" v-slot="{}">
        <PopoverButton
          class="w-full outline-none flex justify-between items-center px-2 py-0.5 rounded hover:bg-gray-200 cursor-pointer"
        >
          <span>Create new group</span>
          <ChevronRightIcon class="w-4 h-4" />
        </PopoverButton>
        <OaiPopoverPanel position="right" class="z-[99]" childClass="flex-col" focus>
          <div
            class="overflow-x-auto overflow-y-hidden px-2 py-1 basis-60 flex gap-2 items-start bg-white ring-1 ring-gray-300 shadow-sm"
          >
            <Combobox
              as="div"
              class="max-h-full flex flex-col w-64"
              v-model="newGroupProcessClass"
              ref="newGroupCombobox"
              static
            >
              <ComboboxInput
                class="w-full rounded-md border border-gray-300 py-1 pl-2 pr-4 shadow-sm text-xs focus:border-yellow-500 focus:outline-none focus:ring-1 focus:ring-yellow-500"
                @change="newGroupProcessClassQuery = $event.target.value"
                @keydown="preventEventPropagation"
              />
              <ComboboxOptions
                class="focus:outline-none w-full mt-1 flex-1 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-gray-500 ring-opacity-5 text-xs"
                static
              >
                <ComboboxOption
                  v-for="item in filteredProcessClasses"
                  :key="item"
                  :value="item"
                  v-slot="{ active, selected }"
                >
                  <li
                    :class="[
                      'relative text-xs cursor-pointer w-full select-none py-1 pl-2 pr-3 flex align-center justify-between',
                      active ? 'bg-orange-600 text-white' : 'text-gray-900',
                      selected ? 'font-semibold' : '',
                    ]"
                  >
                    {{ item }}
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </Combobox>
            <button
              class="px-2 py-0.5 bg-green-500 text-white rounded flex text-sm"
              @click="createNewProcessGroup"
            >
              Add
            </button>
          </div>
        </OaiPopoverPanel>
      </Popover>
      <div class="px-2 py-0.5 rounded hover:bg-gray-200 cursor-pointer" @click="deleteObject">
        Delete
      </div>
    </div>
  </OnClickOutside>
</template>

<script setup lang="ts">
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  PopoverButton,
  Popover,
} from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import { OnClickOutside } from "@vueuse/components";
import * as fabric from "fabric";
import { computed, onMounted, ref } from "vue";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";
import getProcessClasses from "shared/constants/ProcessClasses";
import { DecodedLabel } from "shared/types/ProcessClass";
import { Annotation } from "../Annotation";
import CanvasService from "../services/canvasService";
import GroupService from "../services/groupService";

const props = defineProps<{
  canvasService: CanvasService;
  groupService: GroupService;
}>();

const processClasses = getProcessClasses();

const newGroupCombobox = ref<HTMLButtonElement>();
const contextMenuState = ref<{
  x: number;
  y: number;
  objects: Annotation[];
}>({ x: 0, y: 0, objects: [] });
const newGroupProcessClass = ref<DecodedLabel>();
const newGroupProcessClassQuery = ref("");

const filteredProcessClasses = computed(() => {
  return processClasses
    .map((processClass) => processClass.decodedLabel)
    .filter((processClass) => processClass.includes(newGroupProcessClassQuery.value));
});

onMounted(() => {
  props.canvasService.on("contextmenu", (event) => {
    contextMenuState.value = {
      x: event.x,
      y: event.y,
      objects: event.objects,
    };
  });
});

const closeContextMenu = () => {
  contextMenuState.value = {
    x: 0,
    y: 0,
    objects: [],
  };

  newGroupProcessClassQuery.value = "";
};

const createNewProcessGroup = () => {
  const processClass = newGroupProcessClass.value;
  if (!processClass) {
    return;
  }

  const annotations = contextMenuState.value.objects as Annotation[];

  const group = props.groupService.createGroup(processClass);
  props.groupService.addAnnotationsToGroup(group, annotations);

  closeContextMenu();
};

const addToGroup = (groupId: number) => {
  const annotations = props?.canvasService?.getActiveAnnotations();

  const group = props.groupService.currentFrameGroups?.find((group) => {
    return group.groupId === groupId;
  });

  if (group) {
    props.groupService.addAnnotationsToGroup(group, annotations);
  } else {
    const sequenceGroup = props.groupService.sequenceGroups?.find((group) => {
      return group.group_id === groupId;
    });

    if (!sequenceGroup) {
      return;
    }

    const newGroup = props.groupService.createGroup(
      sequenceGroup.process_class,
      sequenceGroup.group_id,
    );

    props.groupService.addAnnotationsToGroup(newGroup, annotations);
  }

  closeContextMenu();
};

const deleteObject = () => {
  const isGroupSelection = contextMenuState.value.objects.length > 1;

  if (isGroupSelection) {
    const activeSelection = contextMenuState.value.objects[0].group?.canvas?.getActiveObject();
    if (!(activeSelection instanceof fabric.ActiveSelection)) {
      return;
    }

    props.canvasService.deleteObject(activeSelection);
  } else {
    const activeObject = contextMenuState.value.objects[0] as Annotation;

    props.canvasService.deleteObject(activeObject);
  }

  closeContextMenu();
};

const preventEventPropagation = (event: KeyboardEvent) => {
  event.stopPropagation();
};
</script>
