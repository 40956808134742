import { useQuery } from "@tanstack/vue-query";
import ValidationRepository from "pct/src/repositories/ValidationRepository";
import { TrackingEntityEntry } from "pct/src/types/Validation";
import { computed } from "vue";
import { useHasPermission } from "./project";

export const useTrackingEntities = () => {
  const hasPermission = useHasPermission(["pct_admin"]);

  const { data, isLoading } = useQuery<TrackingEntityEntry[]>({
    queryKey: ["tracking-entities"],
    queryFn: () => {
      if (hasPermission) {
        return ValidationRepository.loadTrackingEntities();
      }
      return [];
    },
  });

  const trackingEntities = computed(() => data.value || []);

  return { trackingEntities, isLoading };
};
