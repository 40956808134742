<template>
  <MainLayout :activeItem="'PRD'">
    <OverviewHeader
      :currentUserMail="currentUser?.email || 'all_users'"
      :userOptions="userOptions"
      :trackingEntitiesOptions="trackingEntitiesOptions"
      :selectedTrackingEntity="selectedTrackingEntity"
      :projectStatusFilter="projectStatusFilter"
      :startDate="startDate"
      :endDate="endDate"
      :calendarWeek="calendarWeek"
      @updateUser="updateUser($event)"
      @updateProjectStatus="updateProjectStatus($event)"
      @updateTrackingEntity="updateTrackingEntity($event)"
      @resetFilters="resetFilters"
      @weekChange="handleWeekChange"
    />
    <OverviewList
      v-if="!loading"
      :projects="filteredProjects"
      :dates="dates"
      :timelapseStatusMap="timelapseStatusMap"
      :startDate="startDate"
      :endDate="endDate"
      :levelStatuses="levelStatuses"
      @projectLevelsRecalculated="loadProjectLevelStatuses()"
    />
    <div
      v-if="loading"
      class="loading flex flex-col items-center justify-center w-full flex-1 z-50"
    >
      <LoadingSpinner cls="mb-4" />
      <h2 class="text-center text-xl font-semibold">Loading...</h2>
      <p class="w-1/3 text-center">This may take a few seconds, please don't close this page.</p>
    </div>
  </MainLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useTrackingEntities } from "shared/composables/validation";
import UserRepository from "shared/repositories/UserRepository";
import logger from "shared/services/logger";
import { User } from "shared/types/User";
import MainLayout from "@/components/layout/MainLayout.vue";
import ProjectRepository from "@/repositories/ProjectRepository";
import ValidationRepository from "@/repositories/ValidationRepository";
import { DateInterval } from "@/types/DateInterval";
import { ProjectLevelStatus } from "@/types/Project";
import { ReviewStatusProjectEntry, TimelapseStatusEntry } from "@/types/Validation";
import OverviewHeader from "@/views/process_validation/components/OverviewHeader.vue";
import OverviewList from "@/views/process_validation/components/OverviewList.vue";
import { useStartEndDate } from "@/views/process_validation/composables/general";

export default defineComponent({
  name: "ValidationPrdOverview",
  components: {
    LoadingSpinner,
    OverviewList,
    MainLayout,
    OverviewHeader,
  },
  async mounted() {
    if (this.hasPermission("pct_admin")) {
      this.loadProjectLevelStatuses();
    }
    await this.loadOverviewPage();
    if (this.hasPermission("pct_tracking_admin_prd_review")) {
      await this.loadPctUsers();
      this.currentUser = {} as User;
    } else {
      this.currentUser = this.$store.state.user;
    }
  },
  data() {
    return {
      loading: true as boolean,
      projects: [] as ReviewStatusProjectEntry[],
      dates: [] as string[],
      timelapseStatusMap: {} as Record<string, TimelapseStatusEntry>,
      currentUser: {} as User,
      selectedTrackingEntity: "all_entities" as string,
      users: [] as User[],
      projectStatusFilter: "active" as string,
      levelStatuses: {} as Record<string, ProjectLevelStatus>,
    };
  },
  computed: {
    filteredProjects() {
      if (
        !Object.keys(this.currentUser).length &&
        this.hasPermission("pct_tracking_admin_prd_review")
      ) {
        return this.projects.filter(
          (item) =>
            (this.projectStatusFilter === "active" ? item.tracking_status : true) &&
            (this.selectedTrackingEntity === "all_entities" ||
              item.tracking_entity === this.selectedTrackingEntity),
        );
      }

      if (Object.keys(this.currentUser).length > 0) {
        return this.projects.filter(
          (item) =>
            this.currentUser.projects.some(
              (project) =>
                project.customer_name === item.customer_name && project.site_id === item.site_id,
            ) &&
            (this.projectStatusFilter !== "active" || item.tracking_status) &&
            (this.selectedTrackingEntity === "all_entities" ||
              item.tracking_entity === this.selectedTrackingEntity),
        );
      }

      return [];
    },
    userOptions() {
      return [
        { value: "all_users", name: "All Users" },
        ...this.users
          .filter((item) => item.projects.length > 0)
          .map((user) => ({ value: user.email, name: user.email })),
      ];
    },
    trackingEntitiesOptions() {
      return [
        { value: "all_entities", name: "All Entities" },
        ...(this.trackingEntities?.map((entity) => ({ value: entity._id, name: entity.name })) ||
          []),
      ];
    },
  },
  methods: {
    async loadOverviewPage() {
      this.loading = true;
      await Promise.all([this.loadReviewStatusOveriew(), this.loadTimelapseStatusOverview()]);
      this.loading = false;
    },
    loadReviewStatusOveriew() {
      return ValidationRepository.loadReviewStatusOverview(
        this.startDate,
        this.endDate,
        this.projectStatusFilter,
      )
        .then((data) => {
          this.projects = data.items;
          this.dates = data.dates;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load project overview data");
          }
        });
    },
    loadTimelapseStatusOverview() {
      return ValidationRepository.loadTimelapseStatusOverview(
        this.startDate,
        this.endDate,
        this.projectStatusFilter,
      )
        .then((data) => {
          this.timelapseStatusMap = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load project overview data");
          }
        });
    },
    loadPctUsers() {
      return UserRepository.loadUsers("pct")
        .then((data) => {
          this.users = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load pct users");
          }
        });
    },
    updateUser(email: string) {
      const user = this.users?.find((item) => item.email === email);
      this.currentUser = user ? user : ({} as User);
    },
    updateTrackingEntity(entityId: string) {
      this.selectedTrackingEntity = entityId;
    },
    updateProjectStatus(status: string) {
      this.projectStatusFilter = status;
      this.loadOverviewPage();
    },
    async resetFilters() {
      await this.resetStartEndToDefault();
      if (this.hasPermission("pct_tracking_admin_prd_review")) {
        this.currentUser = {} as User;
      }
      this.selectedTrackingEntity = "all_entities";
      this.projectStatusFilter = "active";
      this.loadOverviewPage();
    },
    loadProjectLevelStatuses() {
      ProjectRepository.loadProjectLevelStatuses()
        .then((items) => {
          this.levelStatuses = items.reduce((acc, item) => {
            acc[`${item.customer_name}_${item.site_id}`] = item;
            return acc;
          }, {} as Record<string, ProjectLevelStatus>);
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    async handleWeekChange(event: DateInterval) {
      await this.changeStartAndEndDate(event.startDate, event.endDate);
      await this.loadOverviewPage();
    },
  },
  setup() {
    const { startDate, endDate, calendarWeek, changeStartAndEndDate, resetStartEndToDefault } =
      useStartEndDate();

    const { trackingEntities } = useTrackingEntities();
    return {
      startDate,
      endDate,
      calendarWeek,
      changeStartAndEndDate,
      resetStartEndToDefault,
      trackingEntities,
    };
  },
});
</script>

<style>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
