<template>
  <div class="flex flex-col gap-1">
    <div class="flex gap-1 cursor-pointer" @click="toggleChange(change._id)">
      <input type="checkbox" class="shrink-0" :checked="selectedChangeIds.has(change._id)" />
      <div class="truncate" v-if="nameChanged">
        <span>{{ change.from.name }}</span>
        <span> ⇨ </span>
        <span>{{ change.to.name }}</span>
      </div>
      <div v-if="nameChanged && costChanged">|</div>
      <div class="truncate" v-if="costChanged">
        <span v-if="!nameChanged">{{ change.to.name }}: </span>
        <span>{{ formatCurrency(change.from.cost) }}</span>
        <span> ⇨ </span>
        <span>{{ formatCurrency(change.to.cost) }}</span>
      </div>
    </div>
    <div v-for="childChange in change.children" :key="childChange._id" class="ml-5">
      <ImportPlanPlannerItemFromTo
        :selectedChangeIds="selectedChangeIds"
        :change="childChange"
        @setChange="$emit('setChange', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Decimal from "decimal.js";
import { defineComponent, PropType } from "vue";
import { getProjectCurrency } from "shared/composables/project";
import numberService from "shared/services/numberService";
import { PlannerItem } from "shared/types/Plan";
import { useCurrentProject } from "@/composables/project";
import { HierarchicalChange } from "@/views/planner/components/import/ImportPlanMixins";

export default defineComponent({
  name: "ImportPlanPlannerItemFromTo",
  props: {
    selectedChangeIds: {
      type: Object as PropType<Set<string>>,
      required: true,
    },
    change: {
      type: Object as PropType<HierarchicalChange<PlannerItem>>,
      required: true,
    },
  },
  emits: ["setChange"],
  computed: {
    nameChanged() {
      return this.change.from.name !== this.change.to.name;
    },
    costChanged() {
      const fallback = Decimal(Infinity);
      const fromCost = this.change.from.cost ?? fallback;
      const toCost = this.change.to.cost ?? fallback;
      return !fromCost.equals(toCost);
    },
  },
  methods: {
    toggleChange(changeId: string) {
      this.$emit("setChange", { changeId, selected: !this.selectedChangeIds.has(changeId) });
    },
    formatCurrency(value: Decimal | null) {
      return numberService.formatCurrency(value, getProjectCurrency(this.currentProject)) ?? "-";
    },
  },
  setup() {
    const currentProject = useCurrentProject();
    return {
      currentProject,
    };
  },
});
</script>
