<template>
  <Disclosure v-slot="{ open }" :defaultOpen="false">
    <DisclosureButton
      class="grid hover:bg-gray-100 text-left"
      :style="{ gridTemplateColumns: '30px 1fr 120px' }"
    >
      <div class="px-2 py-0.5">
        <ChevronRightIcon :class="{ 'rotate-90': open }" class="w-5 h-5" />
      </div>
      <div class="px-2 py-0.5">
        <PlannerItemName v-if="sourceIdsToNames" :name="sourceIdsToNames[actualEvent.source_id]" />
      </div>
      <div class="px-2 py-0.5 text-right">
        {{ readyToCloseActualEvent.working_days_since_last_process }}
      </div>
    </DisclosureButton>
    <DisclosurePanel>
      <div class="flex flex-col gap-3 pl-10 mb-6 border-t pt-3">
        <div class="grid items-start" :style="{ gridTemplateColumns: '1fr 1fr' }">
          <ReadyToCloseModalRecentImage
            :sourceId="actualEvent.source_id"
            :shortenedProcessesWithTags="shortenedProcessesWithTags"
            :hierarchyTags="hierarchyTags"
          />
          <ReadyToCloseModalProcessVideo
            :sourceId="actualEvent.source_id"
            :relevantProcesses="relevantProcesses"
            :currentProcessIndex="currentProcessIndex"
            @setCurrentProcessIndex="currentProcessIndex = $event"
          />
        </div>
        <div class="grid" :style="{ gridTemplateColumns: '1fr 1fr' }">
          <div class="flex flex-col gap-2">
            <Disclosure v-slot="{ open }" :defaultOpen="false" v-if="decodedLabels.length > 0">
              <DisclosureButton>
                <div class="flex gap-1 items-center text-sm -ml-1 px-9">
                  <ChevronRightIcon :class="{ 'rotate-90': open }" class="w-3 h-3 shrink-0" />
                  <div>Process classes</div>
                </div>
              </DisclosureButton>
              <DisclosurePanel>
                <div class="flex flex-col gap-1 text-xs ml-12">
                  <div v-for="decodedLabel in decodedLabels" :key="decodedLabel">
                    {{ decodedLabel }}
                  </div>
                </div>
              </DisclosurePanel>
            </Disclosure>
            <Disclosure
              v-slot="{ open }"
              :defaultOpen="false"
              v-if="otherDecodedLabelsForSectionMask.length > 0"
            >
              <DisclosureButton>
                <div class="flex gap-1 items-center text-sm -ml-1 px-9">
                  <ChevronRightIcon :class="{ 'rotate-90': open }" class="w-3 h-3 shrink-0" />
                  <div>Sibling Process classes</div>
                </div>
              </DisclosureButton>
              <DisclosurePanel>
                <div class="flex flex-col gap-1 text-xs ml-4">
                  <Disclosure
                    v-slot="{ open }"
                    :defaultOpen="false"
                    v-for="item in otherDecodedLabelsForSectionMask"
                    :key="item._id"
                  >
                    <DisclosureButton>
                      <div class="flex gap-1 items-center text-sm -ml-1 px-9">
                        <ChevronRightIcon :class="{ 'rotate-90': open }" class="w-3 h-3 shrink-0" />
                        <div>{{ item.tag.name }}</div>
                      </div>
                    </DisclosureButton>
                    <DisclosurePanel>
                      <div class="flex flex-col gap-1 text-xs ml-12">
                        <div v-for="decodedLabel in item.decodedLabels" :key="decodedLabel">
                          {{ decodedLabel }}
                        </div>
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                </div>
              </DisclosurePanel>
            </Disclosure>
          </div>
          <div class="px-9 flex gap-2 h-min justify-end">
            <Popover>
              <PopoverButton
                class="inline-flex justify-center rounded-md border border-transparent bg-green-300 px-3 py-1 text-sm text-white focus:outline-none hover:bg-green-400"
              >
                Mark as ended
              </PopoverButton>
              <OaiPopoverPanel position="bottom" class="z-[99]">
                <div
                  class="overflow-auto bg-gray-50 ring-1 ring-gray-200 shadow rounded-lg text-sm flex flex-col gap-3 min-w-[180px] w-[180px] px-3 py-4"
                >
                  <VueDatePicker
                    input-class-name="!text-xs"
                    v-model="selectedDate"
                    :enable-time-picker="false"
                    format="dd.MM.yyyy"
                    auto-apply
                    reverse-years
                    :clearable="false"
                  />
                  <button
                    type="button"
                    :disabled="isUpdateActualEventsLoading"
                    @click="setActualEventDate"
                    class="inline-flex justify-center items-center gap-2 rounded-md border border-transparent bg-green-300 px-3 py-1 text-sm text-white focus:outline-none hover:bg-green-400 disabled:bg-gray-300"
                  >
                    Confirm
                    <LoadingSpinner class="w-4 h-4" v-if="isUpdateActualEventsLoading" />
                  </button>
                </div>
              </OaiPopoverPanel>
            </Popover>
            <button
              type="button"
              :disabled="isDeferReadyToCloseLoading"
              class="inline-flex justify-center gap-2 items-center rounded-md border border-transparent bg-orange-300 px-3 py-1 text-sm text-white focus:outline-none hover:bg-orange-400 disabled:bg-gray-300"
              @click="handleDeferReadyToCloseClick"
            >
              Defer
              <LoadingSpinner class="w-4 h-4" v-if="isDeferReadyToCloseLoading" />
            </button>
          </div>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
} from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import VueDatePicker from "@vuepic/vue-datepicker";
import { set, startOfDay } from "date-fns";
import { computed, ref, watch } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiPopoverPanel from "shared/components/other/OaiPopoverPanel.vue";
import { useUpdateActualEvents } from "shared/composables/planner";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import {
  ActualEvent,
  ActualEventChanges,
  PlanConfig,
  ReadyToCloseActualEvent,
} from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProcessClass } from "shared/types/ProcessClass";
import { createHierarchyTagContext } from "shared/views/planner/hierarchyTags";
import { useDeferReadyToClose } from "@/composables/planner";
import { useProcessClasses } from "@/composables/process";
import PlannerItemName from "@/views/planner/components/PlannerItemName.vue";
import ReadyToCloseModalProcessVideo from "@/views/planner/components/ReadyToCloseModalProcessVideo.vue";
import ReadyToCloseModalRecentImage from "@/views/planner/components/ReadyToCloseModalRecentImage.vue";

const props = defineProps<{
  actualEvent: ActualEvent;
  planConfig: PlanConfig;
  readyToCloseActualEvent: ReadyToCloseActualEvent;
  sourceIdsToNames: Record<string, string[]> | undefined;
  shortenedProcessesWithTags: ShortenedProcessWithTags[] | undefined;
  hierarchyTags: HierarchyTagStore[];
}>();

const { updateActualEvents, isLoading: isUpdateActualEventsLoading } = useUpdateActualEvents();

const { deferReadyToClose, isLoading: isDeferReadyToCloseLoading } = useDeferReadyToClose();

const relevantProcesses = computed(() => {
  const processes = (props.shortenedProcessesWithTags || []).filter(
    (process) => process.planner_item_mapping.source_id === props.actualEvent.source_id,
  );
  processes.sort((a, b) => a.start_time.getTime() - b.start_time.getTime());
  return processes;
});

const currentProcessIndex = ref<number>(relevantProcesses.value.length - 1);

const currentProcess = computed(() => relevantProcesses.value[currentProcessIndex.value]);

const getInitialSelectedDate = () =>
  currentProcess.value ? startOfDay(currentProcess.value.start_time) : new Date();

const selectedDate = ref(getInitialSelectedDate());

const processClasses = useProcessClasses();

const processClassesByEncodedLabel = computed(() =>
  processClasses.value.reduce((acc, processClass) => {
    acc[processClass.encodedLabel] = processClass;
    return acc;
  }, {} as Record<string, ProcessClass>),
);

const decodedLabels = computed(() => {
  const componentTag = props.hierarchyTags.find(
    (tag) => tag.source_ids.includes(props.actualEvent.source_id) && tag.type === "component",
  );
  return (componentTag?.attached_processes || [])
    .map(
      (encodedLabel) =>
        processClassesByEncodedLabel.value[encodedLabel]?.decodedLabel || encodedLabel,
    )
    .sort();
});

const hierarchyTagContext = computed(() => createHierarchyTagContext(props.hierarchyTags));

const otherDecodedLabelsForSectionMask = computed(() => {
  const currentPlannerItem = props.planConfig.planner_items.find(
    (plannerItem) => plannerItem.source_id === props.actualEvent.source_id,
  );
  if (!currentPlannerItem) {
    return [];
  }

  const { tagsById, tagsByTypeAndSourceId } = hierarchyTagContext.value;

  const siblingPlannerItems = props.planConfig.planner_items.filter(
    (plannerItem) =>
      plannerItem.parent_id === currentPlannerItem.parent_id &&
      plannerItem._id !== currentPlannerItem._id,
  );

  const componentTag = tagsByTypeAndSourceId[`component_${props.actualEvent.source_id}`];

  const componentTags = siblingPlannerItems
    .map((plannerItem) => tagsByTypeAndSourceId[`component_${plannerItem.source_id}`])
    .filter((tag) => tag && tag._id !== componentTag?._id) as HierarchyTagStore[];
  const uniqueComponentTag = [...new Set(componentTags.map((tag) => tag._id))]
    .map((tagId) => tagsById[tagId])
    .filter((tag) => tag) as HierarchyTagStore[];

  return uniqueComponentTag.map((tag) => ({
    _id: tag._id,
    tag: tag,
    decodedLabels: (tag.attached_processes || []).map(
      (encodedLabel) =>
        processClassesByEncodedLabel.value[encodedLabel]?.decodedLabel || encodedLabel,
    ),
  }));
});

const setActualEventDate = () => {
  const changes: ActualEventChanges = {
    added: [],
    modified: [
      {
        _id: props.actualEvent._id,
        start: props.actualEvent.start,
        end: set(selectedDate.value, { hours: 17 }),
      },
    ],
    removed: [],
  };
  updateActualEvents(changes).catch(() => undefined);
};

const handleDeferReadyToCloseClick = () => {
  deferReadyToClose(props.actualEvent._id).catch(() => undefined);
};

watch([() => props.shortenedProcessesWithTags, currentProcess], () => {
  selectedDate.value = getInitialSelectedDate();
});

watch(relevantProcesses, () => {
  currentProcessIndex.value = relevantProcesses.value.length - 1;
});
</script>
