<template>
  <header
    class="flex flex-none items-center justify-between bg-gray-50 border-b border-gray-200 py-4 px-6 top-0 z-10"
  >
    <div class="flex items-center">
      <div>
        <div class="text-lg font-semibold text-gray-900">Week {{ calendarWeek }}</div>
        <div class="text-xs">{{ `${formatDate(startDate)} - ${formatDate(endDate)} ` }}</div>
      </div>
    </div>
    <div class="flex items-center gap-4">
      <WeekSelector
        :startDate="startDate"
        :endDate="endDate"
        :calendarWeek="calendarWeek"
        @change="$emit('weekChange', $event)"
      />
      <SelectList
        v-if="hasPermission('pct_admin')"
        :options="trackingEntitiesOptions"
        :defaultSelected="selectedTrackingEntity"
        :minWidth="120"
        @update:selected="$emit('updateTrackingEntity', $event)"
      />
      <SelectList
        v-if="hasPermission('pct_tracking_admin_prd_review')"
        :options="userOptions"
        :defaultSelected="currentUserMail"
        :minWidth="220"
        @update:selected="$emit('updateUser', $event)"
      />
      <SelectList
        :options="projectStatuses"
        :defaultSelected="projectStatusFilter"
        :minWidth="120"
        @update:selected="$emit('updateProjectStatus', $event)"
      />
      <button
        type="button"
        class="pl-2 inline-flex items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none"
        @click="$emit('resetFilters')"
      >
        <ArrowUturnLeftIcon class="h-5 w-5 text-white" aria-hidden="true" />
        <span class="pl-2">Reset</span>
      </button>
      <Menu as="div" class="inline-block text-left leading-none">
        <MenuButton class="flex items-center rounded-full text-gray-500 hover:text-gray-600">
          <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-5 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray ring-opacity-5 focus:outline-none"
          >
            <div class="py-1 divide-y">
              <MenuItem v-slot="{ active }">
                <router-link
                  :to="{
                    name: 'QualitySamplingOverviewView',
                    query: { start: formatDateForUrl(startDate), end: formatDateForUrl(endDate) },
                  }"
                  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'extraMenuItem']"
                  class="flex items-center gap-1"
                >
                  <BeakerIcon class="h-4 w-4" aria-hidden="true" />
                  <span class="pl-1">Quality Sampling</span>
                </router-link>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </header>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ArrowUturnLeftIcon, BeakerIcon, EllipsisVerticalIcon } from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import { defineComponent, PropType } from "vue";
import SelectList from "shared/components/other/OaiListbox.vue";
import WeekSelector from "@/views/process_validation/components/inputs/WeekSelector.vue";

export default defineComponent({
  name: "OverviewHeader",
  props: {
    currentUserMail: {
      type: String,
      required: true,
    },
    userOptions: {
      type: Object as PropType<{ value: string; name: string }[]>,
      required: true,
    },
    trackingEntitiesOptions: {
      type: Object as PropType<{ value: string; name: string }[]>,
      required: true,
    },
    selectedTrackingEntity: {
      type: String,
      required: true,
    },
    projectStatusFilter: {
      type: String,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    calendarWeek: {
      type: Number,
      required: true,
    },
  },
  emits: [
    "updateUser",
    "updateProjectStatus",
    "resetFilters",
    "weekChange",
    "updateTrackingEntity",
  ],
  data() {
    return {
      statusList: ["active", "completed"] as Array<string>,
      projectStatuses: [
        { value: "active", name: "Active" },
        { value: "completed", name: "Completed" },
      ],
    };
  },
  components: {
    EllipsisVerticalIcon,
    WeekSelector,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ArrowUturnLeftIcon,
    BeakerIcon,
    SelectList,
  },
  methods: {
    formatDate(date: Date) {
      return format(date, "dd.MM.yyyy");
    },
    formatDateForUrl(date: Date) {
      return format(date, "yyyy-MM-dd");
    },
  },
});
</script>
