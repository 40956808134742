import { Model } from "@bryntum/core-thin";
import { SchedulerPro, EventModel, ResourceModel } from "@bryntum/schedulerpro-thin";
import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-vue-3-thin";
import { PlannerItemTrackingStatus, PlannerMode } from "shared/types/Plan";
import {
  SchedulerProBaseEvent,
  SchedulerProDropEvent,
  SchedulerProEventItem,
  SchedulerProEventMenuEvent,
  SchedulerProMenuItem,
  SchedulerProPasteEvent,
  SchedulerProResourceItem,
} from "shared/types/SchedulerPro";
import {
  baseSchedulerProConfig,
  getChildrenLength,
  getEventType,
  getOculaiSourceId,
  hasEventForMode,
  isModeCompatible,
  isParentResource,
  OculaiTimeRangeStore,
  resetLag,
  RevisionEventModel,
  setDefaultStartAndEnd,
  shiftChildrenEvents,
  VisitorAndTrackerEventModel,
} from "shared/views/planner/SchedulerProConfig";
import {
  updateParentEventsForDependencies,
  updateParentEventsForResourceAndItsParents,
} from "shared/views/planner/SchedulerProParentEvents";

export const schedulerProConfigMode = (
  mode: PlannerMode,
  now: Date,
): Partial<BryntumSchedulerProProps> => {
  const baseConfig = baseSchedulerProConfig(mode);
  return {
    ...baseConfig,
    readOnly: false,
    cellEditFeature: mode === "revision",
    cellMenuFeature: {
      ...baseSchedulerProConfig(mode).cellMenuFeature,
      disabled: mode !== "revision",
      items: {
        copy: {
          icon: "b-fa b-fa-copy",
          text: "Copy Row",
        },
        paste: {
          icon: "b-fa b-fa-paste",
          text: "Paste Row",
        },
        cut: false,
        removeRow: {
          onItem: async (item: SchedulerProResourceItem) => {
            const parent = item.record.parent;
            const deleteEvents = (resource: ResourceModel) => {
              (resource.children as ResourceModel[])?.forEach((childResource) => {
                deleteEvents(childResource);
              });
              const eventsToDelete = item.source.eventStore.getEventsForResource(resource.id);
              item.source.eventStore.remove(eventsToDelete);
            };
            deleteEvents(item.record);
            item.source.resourceStore.remove(item.record);
            await updateParentEventsForResourceAndItsParents(item.source, parent);
            await updateParentEventsForDependencies(item.source, parent);
          },
        },
        addPlannerItem: {
          text: "Add Planner Item",
          icon: "b-fa b-fa-plus",
          onItem: async (item: SchedulerProResourceItem) => {
            const parent = item.source.project.resourceStore.getById(
              item.record.getData("parentId"),
            );
            if (parent) {
              parent.appendChild({ name: "New Planner Item" });
            } else {
              item.source.project.resourceStore.add({ name: "New Planner Item" });
            }
            await updateParentEventsForResourceAndItsParents(item.source, parent);
            await updateParentEventsForDependencies(item.source, parent);
          },
        },
        addChildItem: {
          text: "Append Child",
          icon: "b-fa b-fa-arrow-down",
          onItem: async (item: SchedulerProResourceItem) => {
            item.record.appendChild({ name: "New Child" });
            await updateParentEventsForResourceAndItsParents(item.source, item.record);
            await updateParentEventsForDependencies(item.source, item.record);
          },
        },
      },
    },
    scheduleMenuFeature: {
      ...baseSchedulerProConfig(mode).scheduleMenuFeature,
      disabled: false,
      items: {
        addEvent: {
          text: mode === "tracker" ? "Add Actual" : "Add Planned",
          icon: "b-fa b-fa-plus",
        },
      },
      processItems({
        items,
        record,
      }: {
        items: {
          addEvent: SchedulerProMenuItem<SchedulerProEventItem>;
          editEvent: SchedulerProMenuItem<SchedulerProEventItem>;
        };
        record?: EventModel;
      }) {
        if (!record) {
          items.addEvent.hidden = true;
          return;
        }
        const eventForModeExists = hasEventForMode(mode, record.id, record.project.eventStore);
        const isParent = isParentResource(record);
        const trackingStatus: PlannerItemTrackingStatus = record.getData("trackingStatus");

        if (eventForModeExists || isParent || trackingStatus === "disabled") {
          items.addEvent.hidden = true;
        }
        if (eventForModeExists && !isParent && trackingStatus !== "disabled") {
          items.editEvent = {
            text: mode === "tracker" ? "Edit Actual" : "Edit Planned",
            icon: "b-fa b-fa-pen",
            onItem(item: SchedulerProEventItem) {
              const eventForMode = item.source.eventStore
                .getEventsForResource(item.record.id)
                .find((obj) => isModeCompatible(obj as EventModel, mode));
              if (eventForMode) {
                item.source.editEvent(eventForMode);
              }
            },
          };
        }
      },
    },
    eventMenuFeature: {
      ...baseSchedulerProConfig(mode).eventMenuFeature,
      disabled: false,
      processItems(event: SchedulerProEventMenuEvent) {
        if (!isModeCompatible(event.eventRecord, mode)) {
          return false;
        }
        const isParent = isParentResource(event.resourceRecord);
        if (isParent) {
          event.items.deleteEvent = false;
          event.items.copyEvent = false;
          event.items.editEvent = false;
        }
      },
      items: {
        cutEvent: false,
        splitEvent: false,
        copyEvent: false,
        deleteEvent: {
          text: mode === "tracker" ? "Delete Actual" : "Delete Planned",
          async onItem(e: SchedulerProBaseEvent) {
            const processEvents =
              e.eventRecord.getData("type") === "actual"
                ? e.source.eventStore
                    .getEventsForResource(e.resourceRecord)
                    .filter((event) => event.getData("type")?.startsWith("process_"))
                : [];
            e.source.eventStore.remove([e.eventRecord, ...processEvents]);
            await updateParentEventsForResourceAndItsParents(e.source, e.resourceRecord);
            await updateParentEventsForDependencies(e.source, e.resourceRecord);
          },
        },
        editEvent: {
          text: mode === "tracker" ? "Edit Actual" : "Edit Planned",
          icon: "b-fa b-fa-pen",
          onItem(item: SchedulerProEventMenuEvent) {
            const isValidEvent = isModeCompatible(item.eventRecord, mode);

            if (isValidEvent) {
              item.source.editEvent(item.eventRecord);
            }
          },
        },
      },
    },
    dependenciesFeature: {
      ...baseSchedulerProConfig(mode).dependenciesFeature,
      allowCreate: mode === "revision",
    },
    eventEditFeature: true,
    rowCopyPasteFeature: mode === "revision",
    rowReorderFeature: mode === "revision",
    project: {
      ...baseSchedulerProConfig(mode).project,
      eventModelClass: mode === "revision" ? RevisionEventModel : VisitorAndTrackerEventModel,
      timeRangeStore: new OculaiTimeRangeStore({
        data: [
          {
            id: 1,
            recurrenceRule: "FREQ=WEEKLY;BYDAY=SU;",
            startDate: "1970-01-04 00:00",
            endDate: "1970-01-05 00:00",
          },
        ],
      }),
    },
    listeners: {
      ...baseConfig.listeners,
      beforeEventDrag(this: SchedulerPro, event) {
        resetLag(this, event.eventRecord.id);
        // Prevents switching lines
        const lengthEventsForMode = event.eventRecord.resource.events.filter((obj) =>
          isModeCompatible(obj as EventModel, mode),
        ).length;
        this.features.eventDrag.constrainDragToResource = lengthEventsForMode >= 1;
      },
      async eventDrop(e) {
        const event = e as unknown as SchedulerProDropEvent;
        if (event.eventRecords.length > 0) {
          setDefaultStartAndEnd(
            event.source,
            event.eventRecords[0],
            now,
            event.context.origStart,
            event.context.origEnd,
          );

          await updateParentEventsForResourceAndItsParents(
            event.source,
            event.resourceRecord.parent,
          );
          await updateParentEventsForDependencies(event.source, event.resourceRecord);
        }
        shiftChildrenEvents(event, getEventType(mode));
      },
      async eventResizeEnd(e) {
        const event = e as unknown as SchedulerProBaseEvent;
        setDefaultStartAndEnd(event.source, event.eventRecord, now);
        await updateParentEventsForResourceAndItsParents(event.source, event.resourceRecord.parent);
        await updateParentEventsForDependencies(event.source, event.resourceRecord);
      },
      beforeEventResize(e) {
        const event = e as unknown as SchedulerProBaseEvent;
        const isParent = isParentResource(event.resourceRecord);
        if (!isParent) {
          resetLag(event.source, event.eventRecord.id);
        }
        return !isParent;
      },
      beforeEventDelete(event) {
        if (
          event.eventRecords.filter((obj) => isModeCompatible(obj as EventModel, mode)).length == 0
        ) {
          return false;
        }
        for (let i = 0; i < event.eventRecords.length; i++) {
          const resource = event.source.resourceStore.getById(
            event.eventRecords[i].getData("resourceId"),
          );
          if (getChildrenLength(resource) > 0) {
            return false;
          }
        }
        setTimeout(async () => {
          for (let i = 0; i < event.eventRecords.length; i++) {
            const resource = event.source.resourceStore.getById(
              event.eventRecords[i].getData("resourceId"),
            );
            await updateParentEventsForResourceAndItsParents(event.source, resource.parent);
            await updateParentEventsForDependencies(event.source, resource);
          }
        }, 0);
      },
      beforePaste(event) {
        if (event.entityName !== "row") {
          return false;
        }
      },
      async paste(e) {
        const event = e as unknown as SchedulerProPasteEvent;
        if (event.entityName === "row") {
          const sourceIdMapping = {} as Record<string, string>;

          // Iterate over copy pasted changes
          for (const record of event.records) {
            const queue: Model[] = [];
            queue.push(record);

            while (queue.length > 0) {
              const currentNode = queue.shift();

              // Set new source id + store old source id for later copying of events
              if (currentNode) {
                const oldSourceId = currentNode.getData("sourceId") as string;
                const newSourceId = getOculaiSourceId();
                sourceIdMapping[oldSourceId] = currentNode.id as string;
                currentNode.set("sourceId", newSourceId);
              }
              if (currentNode) {
                for (let i = 0; i < getChildrenLength(currentNode); i++) {
                  queue.push((currentNode.children as Model[])[i]);
                }
              }
            }
          }

          const eventsToCopy: EventModel[] = [];

          // Collect all events for a given mode from original records that are being copied
          for (const sourceRecord of event.originalRecords) {
            const filteredEvent = (
              event.source.eventStore.getEventsForResource(sourceRecord.id) as EventModel[]
            ).find((obj) => isModeCompatible(obj, mode));

            if (filteredEvent) {
              eventsToCopy.push(filteredEvent);
            }
          }

          // Build list for events to add referencing source id
          const eventsToAdd = eventsToCopy.map((obj: EventModel) => ({
            resourceId:
              sourceIdMapping[
                event.source.resourceStore.getById(obj.resourceId).getData("sourceId")
              ],
            startDate: obj.startDate,
            endDate: obj.endDate,
            type: obj.getData("type"),
          }));

          event.source.eventStore.applyChangeset({
            added: eventsToAdd,
          });

          for (const record of event.records) {
            await updateParentEventsForResourceAndItsParents(event.source, record.parent);
            await updateParentEventsForDependencies(event.source, record);
          }
        }
      },
      beforeShowTerminals(e) {
        return e.source.getData("type") === "planned";
      },
      dependencyDblClick(this: SchedulerPro, event) {
        if (mode === "revision") {
          this.dependencyStore.remove(event.dependency);
        }
      },
      beforeDependencyCreateFinalize(e) {
        e.fromSide = "end";
        e.toSide = "top";
        return e.target.getData("type") === "planned";
      },
      afterDependencyCreateDrop(this: SchedulerPro, e) {
        const event = e as unknown as {
          sourceResource: ResourceModel;
          targetResource?: ResourceModel;
        };
        setTimeout(async () => {
          await updateParentEventsForResourceAndItsParents(this, event.sourceResource.parent);
          await updateParentEventsForDependencies(this, event.sourceResource);
          if (event.targetResource) {
            await updateParentEventsForResourceAndItsParents(this, event.targetResource.parent);
            await updateParentEventsForDependencies(this, event.targetResource);
          }
        }, 100);
      },
    },
  } as Partial<BryntumSchedulerProProps>;
};
