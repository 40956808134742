import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-7 text-left" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "flex justify-between items-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "absolute inset-0 bg-white opacity-60"
}
const _hoisted_7 = { class: "flex gap-3 items-center" }
const _hoisted_8 = {
  key: 0,
  class: "flex items-center gap-2"
}
const _hoisted_9 = { class: "flex gap-3 flex-1 justify-end" }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_ImportPlanChanges = _resolveComponent("ImportPlanChanges")!
  const _component_EllipsisHorizontalIcon = _resolveComponent("EllipsisHorizontalIcon")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.open,
    onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('closed'))),
    customCls: "w-full m-5 xl:w-2/3"
  }, {
    title: _withCtx(() => [
      _createTextVNode("Import Plan")
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("input", {
              type: "file",
              ref: "importPlanInputRef",
              accept: ".xml,.mpp,.xer,.pp,.cdpx,.bk3,.fts,.gnt,.gan,.mpd,.mpx,.prx,.db,.ppx,.plf,.pc,.pod,.schedule_grid,.sdef,.stx,.sp,.pep,.xer,.zip",
              class: "hidden",
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.importPlanConfig && _ctx.importPlanConfig(...args)))
            }, null, 544),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.planConfig?.plan.name || "No plan selected"), 1))
              : _createCommentVNode("", true),
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                  key: 1,
                  size: "h-7 w-7"
                }))
              : _createCommentVNode("", true),
            (!_ctx.planConfig)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.importPlanInput.click())),
                  type: "button",
                  disabled: _ctx.loading,
                  class: _normalizeClass(["inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2", [
                _ctx.loading
                  ? 'bg-gray-200 hover:bg-gray-200 cursor-default'
                  : 'bg-yellow-500 hover:bg-yellow-600',
              ]])
                }, " Select plan ", 10, _hoisted_5))
              : _createCommentVNode("", true),
            (_ctx.planConfig)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 3,
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args))),
                  type: "button",
                  class: "inline-flex items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                }, " Clear "))
              : _createCommentVNode("", true)
          ]),
          (_ctx.changes && _ctx.originalPlanConfig)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "relative",
                style: _normalizeStyle(_ctx.overwriteCurrentPlan ? 'filter: grayscale(1)' : '')
              }, [
                _createVNode(_component_ImportPlanChanges, {
                  changes: _ctx.changes as Changes,
                  selectedChangeIds: _ctx.selectedChangeIds,
                  onSetChange: _ctx.handleSetChange,
                  onSetChanges: _ctx.handleSetChanges,
                  style: {"max-height":"500px","overflow":"auto"},
                  merges: _ctx.merges,
                  onSetMerge: _ctx.handleSetMerge
                }, null, 8, ["changes", "selectedChangeIds", "onSetChange", "onSetChanges", "merges", "onSetMerge"]),
                (_ctx.overwriteCurrentPlan)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                  : _createCommentVNode("", true)
              ], 4))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.planConfig && _ctx.originalPlanConfig)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_Switch, {
                    modelValue: _ctx.overwriteCurrentPlan,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.overwriteCurrentPlan) = $event)),
                    class: _normalizeClass([
                  _ctx.overwriteCurrentPlan ? 'bg-green-600' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
                ])
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        class: _normalizeClass([
                    _ctx.overwriteCurrentPlan ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  ])
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass([
                      _ctx.overwriteCurrentPlan
                        ? 'opacity-0 duration-100 ease-out'
                        : 'opacity-100 duration-200 ease-in',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                    ]),
                          "aria-hidden": "true"
                        }, [
                          _createVNode(_component_EllipsisHorizontalIcon, { class: "h-3 w-3 text-gray-400" })
                        ], 2),
                        _createElementVNode("span", {
                          class: _normalizeClass([
                      _ctx.overwriteCurrentPlan
                        ? 'opacity-100 duration-200 ease-in'
                        : 'opacity-0 duration-100 ease-out',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                    ]),
                          "aria-hidden": "true"
                        }, [
                          _createVNode(_component_CheckIcon, { class: "h-3 w-3 text-green-600" })
                        ], 2)
                      ], 2)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "class"]),
                  _createElementVNode("span", {
                    class: _normalizeClass([_ctx.overwriteCurrentPlan ? 'text-black' : 'text-gray-300'])
                  }, "Overwrite current plan", 2)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closed'))),
                type: "button",
                class: "inline-flex items-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              }, " Cancel "),
              (_ctx.planConfig)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args))),
                    type: "button",
                    disabled: !_ctx.hasSomethingToImport,
                    class: _normalizeClass(["inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2", [
                  _ctx.hasSomethingToImport
                    ? 'bg-green-400 hover:bg-green-500'
                    : 'bg-gray-200 cursor-default',
                ]])
                  }, " Apply ", 10, _hoisted_10))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}