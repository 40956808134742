<template>
  <div class="flex-1 flex flex-col overflow-hidden" v-if="projects.length">
    <div class="isolate flex flex-auto flex-col bg-white overflow-x-hidden overflow-y-auto">
      <div
        style="width: 100%"
        class="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
      >
        <div class="flex-none bg-white shadow">
          <div
            class="-mr-px hidden text-sm leading-6 text-gray-500 sm:grid"
            :class="hasPermission('pct_admin') ? ['grid-columns'] : ['grid-cols-8']"
          >
            <div
              class="grid"
              style="margin-right: 1px"
              :class="
                hasPermission('pct_admin') ? 'grid-label-columns' : 'grid-short-label-columns'
              "
            >
              <div class="flex items-center px-3">Project</div>
              <div
                v-if="hasPermission('pct_admin')"
                class="border-l flex items-center justify-center px-3 gap-1"
              >
                <div>Current</div>
                <ArrowPathIcon
                  class="w-4 h-4 cursor-pointer"
                  v-if="hasPermission('pct_admin') && !isRecalculating"
                  @click="recalculateLevels"
                />
                <LoadingSpinner size="w-4 h-4" v-if="isRecalculating" />
              </div>
              <div v-if="hasPermission('pct_admin')" class="border-l flex items-center px-3">
                Next
              </div>
              <div v-if="hasPermission('pct_admin')" class="border-l flex items-center px-3"></div>
            </div>
            <div
              class="flex items-center justify-center py-3 border-gray-200 border border-r-0 border-t-0"
              v-for="(date, index) in dates"
              :key="index"
            >
              {{ weekdaysShort[index] }}
              <span
                class="ml-1"
                :class="{
                  'ml-1.5 flex h-6 w-6 items-center justify-center rounded-full bg-yellow-600 text-white':
                    isToday(date),
                }"
              >
                {{ getDayOfMonth(date) }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="isolate flex flex-col bg-white">
        <div
          class="hidden text-sm text-gray-500 sm:grid"
          :class="hasPermission('pct_admin') ? ['grid-columns'] : ['grid-cols-8']"
          v-for="project in projects"
          :key="project._id"
        >
          <div
            class="border-gray-200 border divide-x divide-gray-200 leading-none grid"
            :class="
              hasPermission('pct_admin') ? ['grid-label-columns'] : ['grid-short-label-columns']
            "
          >
            <router-link
              class="h-full w-full text-sm block py-2 px-3 truncate flex-1"
              :class="[
                hasPermission('pct_tracking_plan', project.customer_name, project.site_id)
                  ? 'hover:bg-gray-100'
                  : 'cursor-default',
              ]"
              :to="
                hasPermission('pct_tracking_plan', project.customer_name, project.site_id)
                  ? {
                      name: 'Planner',
                      params: {
                        customer_name: `${project.customer_name}`,
                        site_id: `${project.site_id}`,
                      },
                    }
                  : ''
              "
            >
              <div class="flex items-center">
                <div>{{ project.customer_name }} - {{ project.site_id }}</div>

                <TrackingEntityTag
                  class="ml-2"
                  v-if="getTrackingEntity(project.tracking_entity) && hasPermission('pct_admin')"
                  :entity="getTrackingEntity(project.tracking_entity)"
                ></TrackingEntityTag>
              </div>
              <div :title="project.name" class="text-gray-500" style="font-size: 11px">
                {{ project.name }}
              </div>
            </router-link>
            <div
              v-if="hasPermission('pct_admin')"
              class="border-l flex flex-col text-xs text-center min-w-0"
            >
              <div
                v-for="(building, index) in getBuildings(project)"
                :key="building.id || 'null'"
                class="flex items-center gap-2 justify-between px-3 flex-1"
                :class="{
                  'bg-gray-100': building.current_level?.has_ceiling_processes,
                  'border-b-2': index < getBuildings(project).length - 1,
                }"
              >
                <template v-if="building.current_level">
                  <div class="tooltip min-w-0">
                    <div class="truncate">
                      {{ building.current_level.name }}
                    </div>
                    <div class="tooltiptext tooltip-right text-xs text-left">
                      {{ building.name ? `${building.name}: ` : "" }}
                      {{ building.current_level.name }}
                    </div>
                  </div>
                  <ProjectLevelStatusSectionIcon
                    :level="building.current_level"
                    @click="
                      sectionMasksTableProps = {
                        customerName: project.customer_name,
                        siteId: project.site_id,
                        buildingId: building.id,
                        currentLevelId: building.current_level.id,
                      }
                    "
                  />
                </template>
                <div v-else>&nbsp;</div>
              </div>
            </div>
            <div
              v-if="hasPermission('pct_admin')"
              class="border-l justify-center flex flex-col text-xs text-center min-w-0"
            >
              <div
                v-for="(building, index) in getBuildings(project)"
                :key="building.id || 'null'"
                class="flex items-center gap-2 justify-between px-3 flex-1"
                :class="{
                  'border-b-2': index < getBuildings(project).length - 1,
                }"
              >
                <template v-if="building.next_level">
                  <div class="tooltip min-w-0">
                    <div class="truncate">
                      {{ building.next_level.name }}
                    </div>
                    <div class="tooltiptext tooltip-right text-xs text-left">
                      {{ building.name ? `${building.name}: ` : "" }}
                      {{ building.next_level.name }}
                    </div>
                  </div>
                  <ProjectLevelStatusSectionIcon
                    :level="building.next_level"
                    @click="
                      sectionMasksTableProps = {
                        customerName: project.customer_name,
                        siteId: project.site_id,
                        buildingId: building.id,
                        currentLevelId: building.next_level.id,
                      }
                    "
                  />
                </template>
                <div v-else>&nbsp;</div>
              </div>
            </div>
            <div
              v-if="hasPermission('pct_tracking_plan', project.customer_name, project.site_id)"
              class="flex flex-col items-center justify-center min-w-0 truncate text-xs text-white font-semibold"
              :class="[
                getReadyToClose(project) && getReadyToClose(project) > 0
                  ? 'bg-red-300 cursor-pointer'
                  : '',
              ]"
              @click="handleReadyToCloseClick(project)"
            >
              <span v-if="getReadyToClose(project) > 0">{{ getReadyToClose(project) }}</span>
            </div>
            <router-link
              v-if="hasPermission('pct_admin')"
              class="hover:bg-gray-100 h-full p-2 flex items-center"
              :to="{
                name: 'GlobalProcessValidation',
                query: getProcessFilters(project),
              }"
            >
              <ArrowDownLeftIcon class="h-4 w-4" />
            </router-link>
          </div>

          <div
            class="flex border-b border-gray-200 m-0.5"
            v-for="item in project.dates"
            :key="item.date"
          >
            <template v-for="(stage, idx) in stages" :key="stage">
              <router-link
                v-if="
                  stage === 'review' ||
                  (stage === 'check' &&
                    hasPermission('pct_tracking_prd_check', project.customer_name, project.site_id))
                "
                :to="
                  hasPermission(
                    [
                      'pct_tracking_prd_review',
                      'pct_tracking_prd_check',
                      'pct_tracking_admin_prd_review',
                    ],
                    project.customer_name,
                    project.site_id,
                  )
                    ? {
                        name: 'ValidationPrdDate',
                        params: {
                          date: `${item.date}`,
                          customer_name: `${project.customer_name}`,
                          site_id: `${project.site_id}`,
                        },
                        query: {
                          camera_id: item.entries[0]?.camera_id,
                        },
                      }
                    : ''
                "
                :class="[
                  `border-gray-300 bg-green-100 flex-1 flex items-center justify-center ${resolveStatus(
                    project,
                    item,
                    stage,
                  )}`,
                  { 'border-r': idx < stages.length - 1 },
                ]"
              >
                <MinusIcon
                  v-if="resolveStatus(project, item, stage) === 'no_data_possible'"
                  class="h-auto w-3.5"
                />
                <XMarkIcon
                  v-if="resolveStatus(project, item, stage) === 'no_data'"
                  class="h-auto w-3.5"
                ></XMarkIcon>
                <PencilIcon
                  v-if="resolveStatus(project, item, stage) === 'no_section_masks'"
                  class="h-auto w-3.5"
                />
                <CheckIcon
                  v-if="
                    ['ops_reviewed', 'ops_checked'].includes(resolveStatus(project, item, stage))
                  "
                  class="h-auto w-3.5"
                />
                <ChevronRightIcon
                  v-if="resolveStatus(project, item, stage) === 'ready_to_validate'"
                  class="h-auto w-3.5"
                />
                <EllipsisHorizontalIcon
                  v-if="
                    ['ops_reviewed_partially', 'ops_checked_partially'].includes(
                      resolveStatus(project, item, stage),
                    )
                  "
                  class="h-auto w-3.5"
                />
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :open="true" v-if="sectionMasksTableProps" @close="sectionMasksTableProps = null">
    <template #content>
      <SectionMaskTable
        :customerName="sectionMasksTableProps.customerName"
        :siteId="sectionMasksTableProps.siteId"
        :buildingId="sectionMasksTableProps.buildingId"
        :currentLevelId="sectionMasksTableProps.currentLevelId"
      />
    </template>
  </Modal>
  <ProjectProvider
    v-if="isReadyToCloseModalProject"
    :customerName="isReadyToCloseModalProject.customer_name"
    :siteId="isReadyToCloseModalProject.site_id"
  >
    <ReadyToCloseModal @close="isReadyToCloseModalProject = null" />
  </ProjectProvider>
</template>

<script lang="ts">
import {
  MinusIcon,
  CheckIcon,
  XMarkIcon,
  ChevronRightIcon,
  ArrowDownLeftIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/vue/20/solid";
import { PencilIcon, ArrowPathIcon } from "@heroicons/vue/24/outline";
import { parseISO, getDate, isToday, startOfDay, isEqual, format } from "date-fns";
import { defineComponent, PropType } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import ProjectProvider from "shared/components/other/ProjectProvider.vue";
import { useTrackingEntities } from "shared/composables/validation";
import logger from "shared/services/logger";
import { ProjectPlannerStat } from "shared/types/Plan";
import TrackingEntityTag from "@/components/other/TrackingEntityTag.vue";
import { useProjectPlannerStats } from "@/composables/planner";
import ProjectRepository from "@/repositories/ProjectRepository";
import { ProjectLevelStatus } from "@/types/Project";
import {
  ReviewStatusDateEntry,
  ReviewStatusStreamEntry,
  ReviewStatusProjectEntry,
  TimelapseStatusEntry,
} from "@/types/Validation";
import ReadyToCloseModal from "@/views/planner/components/ReadyToCloseModal.vue";
import ProjectLevelStatusSectionIcon from "@/views/process_validation/components/ProjectLevelStatusSectionIcon.vue";
import SectionMaskTable from "@/views/process_validation/components/SectionMaskTable.vue";

export default defineComponent({
  props: {
    projects: {
      type: Object as PropType<ReviewStatusProjectEntry[]>,
      required: true,
    },
    dates: {
      type: Object as PropType<string[]>,
      required: true,
    },
    timelapseStatusMap: {
      type: Object as PropType<Record<string, TimelapseStatusEntry>>,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    levelStatuses: {
      type: Object as PropType<Record<string, ProjectLevelStatus>>,
      required: true,
    },
  },
  data() {
    return {
      weekdaysShort: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      sectionMasksTableProps: null as {
        customerName: string;
        siteId: string;
        buildingId: string | null;
        currentLevelId: string;
      } | null,
      isRecalculating: false,
      isReadyToCloseModalProject: null as ReviewStatusProjectEntry | null,
    };
  },
  emits: ["projectLevelsRecalculated"],
  components: {
    TrackingEntityTag,
    ReadyToCloseModal,
    LoadingSpinner,
    ProjectLevelStatusSectionIcon,
    MinusIcon,
    CheckIcon,
    XMarkIcon,
    PencilIcon,
    ChevronRightIcon,
    ArrowDownLeftIcon,
    EllipsisHorizontalIcon,
    SectionMaskTable,
    Modal,
    ArrowPathIcon,
    ProjectProvider,
  },
  computed: {
    stages() {
      return ["review", "check"] as const;
    },
    projectPlannerStatsByCustomer() {
      return (this.projectPlannerStats || []).reduce((acc, stat) => {
        acc[`${stat.customer_name}_${stat.site_id}`] = stat;
        return acc;
      }, {} as Record<string, ProjectPlannerStat>);
    },
  },
  methods: {
    getDayOfMonth(date: string) {
      return getDate(parseISO(date));
    },
    isToday(date: string) {
      return isToday(parseISO(date));
    },
    resolveStatus(
      project: ReviewStatusProjectEntry,
      item: ReviewStatusDateEntry,
      stage: "review" | "check",
    ) {
      const dateObj = new Date(item.date as string);
      if (dateObj.getDay() === 0 || dateObj > new Date() || item.entries.length === 0) {
        return "no_data_possible";
      }
      const statusObj = {
        no_data: 0,
        ops_reviewed: 0,
        ops_checked: 0,
        has_section_mask: 0,
      };
      item.entries.forEach((stream_item: ReviewStatusStreamEntry) => {
        const tmlKey = `${project.customer_name}#${project.site_id}#${stream_item.camera_id}`;
        if (tmlKey in this.timelapseStatusMap && !this.timelapseStatusMap[tmlKey][item.date]) {
          statusObj.no_data += 1;
        } else {
          if (stream_item.has_section_mask) {
            statusObj.has_section_mask += 1;
          }
        }
        if (stream_item.ops_reviewed) {
          statusObj.ops_reviewed += 1;
        }
        if (stream_item.ops_checked) {
          statusObj.ops_checked += 1;
        }
      });
      if (statusObj.no_data === item.entries.length) {
        if (isEqual(startOfDay(dateObj), startOfDay(new Date()))) {
          return "no_data_possible";
        }
        return "no_data";
      }
      if (statusObj.has_section_mask === 0) {
        return "no_section_masks";
      }
      if (stage === "review") {
        if (
          statusObj.ops_reviewed >=
          item.entries.length -
            (item.entries.length - statusObj.has_section_mask - statusObj.no_data) -
            statusObj.no_data
        ) {
          return "ops_reviewed";
        }
        if (statusObj.ops_reviewed !== 0) {
          return "ops_reviewed_partially";
        }
      }
      if (stage === "check") {
        if (
          statusObj.ops_checked >=
          item.entries.length -
            (item.entries.length - statusObj.has_section_mask - statusObj.no_data) -
            statusObj.no_data
        ) {
          return "ops_checked";
        }
        if (statusObj.ops_checked !== 0) {
          return "ops_checked_partially";
        }
      }

      return "ready_to_validate";
    },
    getProcessFilters(project: ReviewStatusProjectEntry) {
      return {
        filter_customerName: `${project.customer_name}`,
        filter_siteId: `${project.site_id}`,
        filter_startDate: format(this.startDate, "yyyy-MM-dd"),
        filter_endDate: format(this.endDate, "yyyy-MM-dd"),
      };
    },
    getBuildings(project: ReviewStatusProjectEntry) {
      return (
        this.levelStatuses[`${project.customer_name}_${project.site_id}`]?.buildings.filter(
          (building) => building.current_level || building.next_level,
        ) || []
      );
    },
    recalculateLevels() {
      this.isRecalculating = true;
      ProjectRepository.recalculateProjectLevelStatuses()
        .then(() => {
          this.$emit("projectLevelsRecalculated");
        })
        .catch((error) => {
          logger.error(error);
          alert("Unable to recalculate");
        })
        .finally(() => {
          this.isRecalculating = false;
        });
    },
    getReadyToClose(project: ReviewStatusProjectEntry) {
      return this.projectPlannerStatsByCustomer[
        `${project.customer_name}_${project.site_id}`
      ]?.ready_to_close.filter((item) => !item.defer_until).length;
    },
    handleReadyToCloseClick(project: ReviewStatusProjectEntry) {
      if (this.getReadyToClose(project) > 0) {
        this.isReadyToCloseModalProject = project;
      }
    },
    getTrackingEntity(entityId: string) {
      return this.trackingEntities?.find((entity) => entity._id === entityId);
    },
  },
  setup() {
    const { projectPlannerStats } = useProjectPlannerStats();
    const { trackingEntities } = useTrackingEntities();
    return {
      projectPlannerStats,
      trackingEntities,
    };
  },
});
</script>
<style scoped>
.no_data_possible {
  @apply bg-gray-200 text-gray-700;
}

.no_data {
  @apply bg-red-200 text-red-700;
}

.no_section_masks {
  @apply bg-gray-400 text-gray-900;
}

.ready_to_validate {
  @apply bg-yellow-200 text-yellow-700;
}

.ops_reviewed {
  @apply bg-green-200 text-green-700;
}

.ops_reviewed_partially {
  @apply bg-green-100 text-green-700;
}

.ops_checked {
  @apply bg-purple-200 text-purple-700;
}

.ops_checked_partially {
  @apply bg-purple-100 text-purple-700;
}

.grid-label-columns {
  grid-template-columns: 5fr 3fr 3fr 35px 35px;
}

.grid-short-label-columns {
  grid-template-columns: 1fr 35px;
}

.grid-columns {
  grid-template-columns: 3fr repeat(7, 1fr);
}
</style>
