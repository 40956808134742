<template>
  <div class="flex gap-2 flex-col lg:flex-row lg:items-center">
    <SearchList
      :defaultOptions="customerNames"
      :editMode="true"
      placeholder="Customer"
      :selectedValue="filters.customerName || ''"
      @updateEvent="setCustomerName($event)"
      :nullable="true"
    />
    <SearchList
      :defaultOptions="siteIds"
      :editMode="true"
      placeholder="Site"
      :selectedValue="filters.siteId || ''"
      @updateEvent="setSiteId($event)"
      :nullable="true"
    />
    <SearchList
      :defaultOptions="cameraIds"
      :editMode="true"
      placeholder="Camera"
      :selectedValue="filters.cameraId || ''"
      @updateEvent="
        $emit('changed', {
          ...filters,
          cameraId: $event || undefined,
        })
      "
      :nullable="true"
    />
    <SearchList
      :defaultOptions="decodedProcessLabels"
      :editMode="true"
      placeholder="Process Class"
      :selectedValue="filters.processClass || ''"
      @updateEvent="
        $emit('changed', {
          ...filters,
          processClass: $event || undefined,
        })
      "
      :nullable="true"
    />
    <input
      type="date"
      class="shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2"
      :value="formatDate(filters.startDate)"
      @change="
        $emit('changed', {
          ...filters,
          startDate: parseDate(($event.target as HTMLInputElement).value),
        })
      "
      title="Start date"
    />
    <input
      type="date"
      class="shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2"
      :value="formatDate(filters.endDate)"
      @change="
        $emit('changed', {
          ...filters,
          endDate: parseDate(($event.target as HTMLInputElement).value),
        })
      "
      title="End date"
    />
    <SearchList
      :defaultOptions="buildings"
      :editMode="true"
      placeholder="Building"
      :selectedValue="filters.building || ''"
      @updateEvent="
        $emit('changed', {
          ...filters,
          building: $event || undefined,
        })
      "
      :nullable="true"
    />
    <SearchList
      :defaultOptions="levels"
      :editMode="true"
      placeholder="Level"
      :selectedValue="filters.level || ''"
      @updateEvent="
        $emit('changed', {
          ...filters,
          level: $event || undefined,
        })
      "
      :nullable="true"
    />
    <SearchList
      :defaultOptions="sections"
      :editMode="true"
      placeholder="Section"
      :selectedValue="filters.section || ''"
      @updateEvent="
        $emit('changed', {
          ...filters,
          section: $event || undefined,
        })
      "
      :nullable="true"
    />
    <SearchList
      :defaultOptions="['all', 'conflicting', 'ignored']"
      :editMode="true"
      placeholder="Mapping State"
      :selectedValue="filters.mappingState || 'all'"
      @updateEvent="
        $emit('changed', {
          ...filters,
          mappingState: ['conflicting', 'ignored'].includes($event) ? $event : undefined,
        })
      "
    />
    <span
      class="hidden lg:block relative text-xs bg-red-500 right-0 text-white rounded-full px-2 py-1"
      style="top: -20px; right: 20px; padding-left: 7px"
      v-if="$store.state.conflictingProcessesCount > 0"
      >{{ $store.state.conflictingProcessesCount }}</span
    >
    <span
      class="absolute bg-green-200 rounded-full top-0 right-0 p-1"
      v-if="$store.state.conflictingProcessesCount === 0"
    >
      <CheckIcon class="w-4 h-4 text-white" />
    </span>
    <button
      type="button"
      @click="
        $emit('changed', {
          customerName: undefined,
          siteId: undefined,
          cameraId: undefined,
          processClass: undefined,
          startDate: undefined,
          endDate: undefined,
          building: undefined,
          level: undefined,
          section: undefined,
          mappingState: undefined,
        })
      "
      class="lg:ml-3 rounded-md bg-gray-400 px-4 py-0 text-sm text-white hover:bg-gray-600 h-9"
    >
      Reset
    </button>
  </div>
</template>

<script lang="ts">
import { CheckIcon } from "@heroicons/vue/24/outline";
import { format, isValid, parse } from "date-fns";
import { defineComponent, PropType } from "vue";
import HierarchyRepository from "shared/repositories/HierarchyRepository";
import StreamRepository from "shared/repositories/StreamRepository";
import logger from "shared/services/logger";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import { Project } from "shared/types/Project";
import { Stream } from "shared/types/Stream";
import SearchList from "@/components/other/SearchList.vue";
import { useProcessClasses } from "@/composables/process";
import ProjectRepository from "@/repositories/ProjectRepository";
import { ProcessFilters } from "@/types/Process";

export default defineComponent({
  name: "GlobalProcessValidationFilterBar",
  props: {
    filters: {
      type: Object as PropType<ProcessFilters>,
      required: true,
    },
  },
  data() {
    return {
      projects: [] as Project[],
      streams: [] as Stream[],
      hierarchyTagsByCustomer: {} as Record<string, HierarchyTagStore[]>,
    };
  },
  setup() {
    const processClasses = useProcessClasses();
    return {
      processClasses,
    };
  },
  emits: ["changed"],
  components: {
    SearchList,
    CheckIcon,
  },
  mounted() {
    this.loadProjects();
    this.loadStreams();
    this.loadHierarchyTags(this.filters.customerName, this.filters.siteId);
  },
  computed: {
    decodedProcessLabels() {
      return this.processClasses
        .map((item) => item.decodedLabel)
        .sort((a, b) => a.localeCompare(b));
    },
    customerNames() {
      const customerNames = [...new Set(this.projects.map((project) => project.customer_name))];
      customerNames.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      return customerNames;
    },
    siteIds() {
      return this.getSiteIds(this.filters.customerName);
    },
    cameraIds() {
      return this.getCameraIds(this.filters.customerName, this.filters.siteId);
    },
    buildings() {
      return this.getHierarchyTagOptions("building");
    },
    levels() {
      return this.getHierarchyTagOptions("level");
    },
    sections() {
      return this.getHierarchyTagOptions("section");
    },
  },
  methods: {
    loadProjects() {
      ProjectRepository.loadProjects()
        .then((projects) => {
          this.projects = projects;
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    loadStreams() {
      StreamRepository.loadAllStreams()
        .then((streams) => (this.streams = streams))
        .catch((error) => {
          logger.error(error);
        });
    },
    loadHierarchyTags(customerName: string | undefined, siteId: string | undefined) {
      if (!customerName || !siteId) {
        return;
      }
      const key = `${customerName}_${siteId}`;
      if (key in this.hierarchyTagsByCustomer) {
        return this.hierarchyTagsByCustomer[key];
      }
      HierarchyRepository.loadHierarchyTags(customerName, siteId)
        .then((hierarchyTags) => {
          this.hierarchyTagsByCustomer[key] = hierarchyTags;
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    getSiteIds(customerName: string | undefined) {
      const siteIds = this.projects
        .filter((project) => project.customer_name === customerName)
        .map((project) => project.site_id);
      siteIds.sort();
      return siteIds;
    },
    getCameraIds(customerName: string | undefined, siteId: string | undefined) {
      const cameraIds = this.streams
        .filter((stream) => stream.customer_name === customerName && stream.site_id === siteId)
        .map((project) => project.camera_id);
      cameraIds.sort();
      return cameraIds;
    },
    parseDate(dateString: string) {
      const date = parse(dateString, "yyyy-MM-dd", new Date());
      return isValid(date) ? date : undefined;
    },
    formatDate(date: Date | undefined) {
      return date ? format(date, "yyyy-MM-dd") : undefined;
    },
    setCustomerName(customerName: string) {
      const siteIds = this.getSiteIds(customerName);
      const siteId = siteIds.length === 1 ? siteIds[0] : undefined;
      const cameraIds = this.getCameraIds(customerName, siteId);
      const cameraId = cameraIds.length === 1 ? cameraIds[0] : undefined;
      const finalCustomerName = customerName || undefined;
      this.$emit("changed", {
        ...this.filters,
        customerName: finalCustomerName,
        siteId,
        cameraId,
      });
      this.loadHierarchyTags(finalCustomerName, siteId);
    },
    setSiteId(siteId: string) {
      const cameraIds = this.getCameraIds(this.filters.customerName, siteId);
      const cameraId = cameraIds.length === 1 ? cameraIds[0] : undefined;
      const finalSiteId = siteId || undefined;
      this.$emit("changed", {
        ...this.filters,
        siteId: finalSiteId,
        cameraId,
      });
      this.loadHierarchyTags(this.filters.customerName, finalSiteId);
    },
    getHierarchyTagOptions(type: HierarchyType) {
      if (!this.filters.customerName || !this.filters.siteId) {
        return [];
      }
      const key = `${this.filters.customerName}_${this.filters.siteId}`;
      const hierarchyTags = this.hierarchyTagsByCustomer[key] || [];
      const buildingTags = hierarchyTags.filter((tag) => tag.type === type);
      buildingTags.sort((a, b) => a.number - b.number);
      return buildingTags.map((tag) => tag.name);
    },
  },
});
</script>
