import en from "app/src/assets/i18n/en.json";
import { createI18n } from "vue-i18n";
import { setInstance } from "shared/i18n";

const customPluralRule = (choice: number) => (choice === 1 ? 0 : 1);

const i18n = createI18n({
  locale: "en",
  messages: {
    en,
  },
  pluralRules: {
    en: customPluralRule,
  },
  legacy: false,
});

setInstance(i18n.global);

export default i18n;
