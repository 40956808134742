<template>
  <QuickSearch v-if="isQuickSearchOpen" @closed="isQuickSearchOpen = false" />
  <div class="flex flex-col lg:flex-row h-dvh">
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog as="div" class="relative z-40 lg:hidden" @close="mobileMenuOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600/75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-4">
                  <button
                    type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="mobileMenuOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="pt-5 pb-4 overflow-x-hidden overflow-y-auto">
                <nav aria-label="Sidebar" class="mt-5">
                  <div class="space-y-1 px-2">
                    <router-link
                      v-for="item in navigatorForMobile"
                      :to="{ name: `${item.to}` }"
                      :key="item.name"
                      :class="[
                        item.current
                          ? 'bg-yellow-800 text-white'
                          : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                        'group w-full p-3 rounded-md flex items-center text-xs font-medium',
                      ]"
                      :aria-current="item.current ? 'page' : undefined"
                    >
                      <component
                        :is="item.icon"
                        :class="[
                          item.current ? 'text-yellow' : 'group-hover:text-yellow',
                          'h-6 w-6',
                        ]"
                        aria-hidden="true"
                      />
                      <span class="ml-2">{{ item.name }}</span>
                    </router-link>
                    <router-link
                      v-if="hasPermission(['pct_admin'])"
                      :to="{ name: 'GlobalProcessValidation' }"
                      :class="[
                        activeItem === 'GlobalProcessValidation'
                          ? 'bg-yellow-800 text-white'
                          : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                        'group w-full p-3 rounded-md flex items-center text-xs font-medium',
                      ]"
                      :aria-current="activeItem === 'GlobalProcessValidation' ? 'page' : undefined"
                    >
                      <AdjustmentsVerticalIcon
                        :class="[
                          activeItem === 'GlobalProcessValidation'
                            ? 'text-yellow'
                            : 'group-hover:text-yellow',
                          'h-6 w-6',
                        ]"
                        aria-hidden="true"
                      />
                      <span class="ml-2 inline-flex items-center gap-2"
                        >Processes
                        <span
                          v-if="$store.state.conflictingProcessesCount > 0"
                          class="bg-red-500 text-white px-2 py-1 rounded-full"
                          >{{ $store.state.conflictingProcessesCount }}</span
                        >
                        <span
                          class="bg-green-200 rounded-full p-1 inline-flex"
                          v-if="$store.state.conflictingProcessesCount === 0"
                        >
                          <CheckIcon class="w-3 h-3 text-white" /> </span
                      ></span>
                    </router-link>
                    <router-link
                      v-if="hasPermission(['pct_admin'])"
                      :to="{ name: 'Streams' }"
                      :class="[
                        activeItem === 'Streams'
                          ? 'bg-yellow-800 text-white'
                          : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                        'group w-full p-3 rounded-md flex items-center text-xs font-medium',
                      ]"
                      :aria-current="activeItem === 'Streams' ? 'page' : undefined"
                    >
                      <VideoCameraIcon
                        :class="[
                          activeItem === 'Streams' ? 'text-yellow' : 'group-hover:text-yellow',
                          'h-6 w-6',
                        ]"
                        aria-hidden="true"
                      />
                      <span class="ml-2 inline-flex items-center gap-2"
                        >Streams<span
                          v-if="$store.state.offlineStreamCount > 0"
                          class="bg-red-500 text-white px-2 py-1 rounded-full"
                          >{{ $store.state.offlineStreamCount }}</span
                        >
                        <span
                          class="bg-green-200 rounded-full p-1 inline-flex"
                          v-if="$store.state.offlineStreamCount === 0"
                        >
                          <CheckIcon class="w-3 h-3 text-white" /> </span
                      ></span>
                    </router-link>
                    <router-link
                      v-if="hasPermission(['pct_admin'])"
                      :to="{ name: 'UnitValuesView' }"
                      :class="[
                        activeItem === 'UnitValuesView'
                          ? 'bg-yellow-800 text-white'
                          : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                        'group w-full p-3 rounded-md flex items-center text-xs font-medium',
                      ]"
                      :aria-current="activeItem === 'ProjectConsole' ? 'page' : undefined"
                    >
                      <ChartPieIcon
                        :class="[
                          activeItem === 'UnitValuesView'
                            ? 'text-yellow'
                            : 'group-hover:text-yellow',
                          'h-6 w-6',
                        ]"
                        aria-hidden="true"
                      />
                      <span class="ml-2">Benchmarks</span>
                    </router-link>
                    <router-link
                      v-if="hasPermission(['pct_admin'])"
                      :to="{ name: 'Demo' }"
                      :class="[
                        activeItem === 'Demo'
                          ? 'bg-yellow-800 text-white'
                          : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                        'group w-full p-3 rounded-md flex items-center text-xs font-medium',
                      ]"
                      :aria-current="activeItem === 'Demo' ? 'page' : undefined"
                    >
                      <ComputerDesktopIcon
                        :class="[
                          activeItem === 'Demo' ? 'text-yellow' : 'group-hover:text-yellow',
                          'h-6 w-6',
                        ]"
                        aria-hidden="true"
                      />
                      <span class="ml-2">Demos</span>
                    </router-link>
                    <router-link
                      v-if="hasPermission(['pct_admin'])"
                      :to="{ name: 'ProjectConsole' }"
                      :class="[
                        activeItem === 'ProjectConsole'
                          ? 'bg-yellow-800 text-white'
                          : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                        'group w-full p-3 rounded-md flex items-center text-xs font-medium',
                      ]"
                      :aria-current="activeItem === 'ProjectConsole' ? 'page' : undefined"
                    >
                      <Cog8ToothIcon
                        :class="[
                          activeItem === 'ProjectConsole'
                            ? 'text-yellow'
                            : 'group-hover:text-yellow',
                          'h-6 w-6',
                        ]"
                        aria-hidden="true"
                      />
                      <span class="ml-2">Console</span>
                    </router-link>
                    <div
                      v-if="hasPermission(['pct_admin'])"
                      :class="[
                        'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow group w-full p-3 rounded-md flex items-center text-xs font-medium',
                      ]"
                      @click="openQuickSearchMobile()"
                    >
                      <MagnifyingGlassIcon
                        class="group-hover:text-yellow h-6 w-6"
                        aria-hidden="true"
                      />
                      <span class="ml-2">Search</span>
                    </div>
                  </div>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 shrink-0" aria-hidden="true">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <div class="hidden lg:flex lg:shrink-0">
      <div class="flex w-20 flex-col relative">
        <div class="flex min-h-0 flex-1 flex-col overflow-y-auto bg-oaiGray">
          <div class="flex-1">
            <div class="flex items-center justify-center bg-oaiGray pt-4 pb-2">
              <router-link :to="{ name: 'ValidationPrdOverview' }">
                <img
                  class="h-8 w-auto"
                  src="@/assets/imgs/logo/icon_multi-white.png"
                  alt="Oculai logo"
                />
              </router-link>
            </div>
            <nav aria-label="Sidebar" class="flex flex-col items-center space-y-3 pt-4">
              <div v-for="item in navigation" :key="item.name" class="w-full">
                <div v-if="item.items" class="group">
                  <div
                    class="absolute left-full hidden group-hover:flex z-10 py-3 bg-oaiGray text-oaiGray-50 gap-1 rounded-r-md flex-col"
                  >
                    <router-link
                      class="gap-2 px-4 py-1 hover:text-yellow flex items-center whitespace-nowrap hover:bg-yellow-800 cursor-pointer"
                      v-for="subTab in item.items"
                      :key="subTab.name"
                      :to="{ name: `${subTab.to}` }"
                      :aria-current="subTab.current ? 'page' : undefined"
                    >
                      <component
                        :is="subTab.icon"
                        :class="[subTab.current && 'text-yellow', 'h-5 w-5']"
                        aria-hidden="true"
                      />
                      <span :class="['text-sm', subTab.current && 'text-white']">{{
                        subTab.name
                      }}</span>
                    </router-link>
                  </div>
                  <div
                    :class="[
                      'w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium',
                      item.current
                        ? 'bg-yellow-800 text-white'
                        : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                    ]"
                  >
                    <div :class="item.current ? 'text-yellow' : 'group-hover:text-yellow'">
                      <component
                        :is="item.icon"
                        :class="[
                          item.current ? 'text-yellow' : 'group-hover:text-yellow',
                          'h-6 w-6',
                        ]"
                        aria-hidden="true"
                      />
                      <span class="mt-2">{{ item.name }}</span>
                    </div>
                  </div>
                </div>

                <router-link
                  v-else
                  :to="{ name: `${item.to}` }"
                  :key="item.name"
                  :class="[
                    item.current
                      ? 'bg-yellow-800 text-white'
                      : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                    'relative group w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  <component
                    :is="item.icon"
                    :class="[item.current ? 'text-yellow' : 'group-hover:text-yellow', 'h-6 w-6']"
                    aria-hidden="true"
                  />
                  <span class="mt-2"
                    >{{ item.name }}
                    <span
                      class="absolute bg-red-500 right-0 text-white rounded-full px-2 py-1"
                      style="top: -1px; padding-left: 7px"
                      v-if="item.number !== undefined"
                      >{{ item.number }}</span
                    ></span
                  >
                </router-link>
              </div>
              <router-link
                v-if="hasPermission(['pct_admin'])"
                :to="{ name: 'GlobalProcessValidation' }"
                :class="[
                  activeItem === 'GlobalProcessValidation'
                    ? 'bg-yellow-800 text-white'
                    : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                  'group w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium relative',
                ]"
                :aria-current="activeItem === 'GlobalProcessValidation' ? 'page' : undefined"
              >
                <AdjustmentsVerticalIcon
                  :class="[
                    activeItem === 'GlobalProcessValidation'
                      ? 'text-yellow'
                      : 'group-hover:text-yellow',
                    'h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                <span class="mt-2"
                  >Processes
                  <span
                    class="absolute bg-red-500 right-0 text-white rounded-full px-2 py-1"
                    style="top: -1px; padding-left: 7px"
                    v-if="$store.state.conflictingProcessesCount > 0"
                    >{{ $store.state.conflictingProcessesCount }}</span
                  >
                  <span
                    class="absolute bg-green-200 rounded-full top-0 right-0 p-1"
                    v-if="$store.state.conflictingProcessesCount === 0"
                  >
                    <CheckIcon class="w-4 h-4 text-white" />
                  </span>
                </span>
              </router-link>
              <router-link
                v-if="hasPermission(['pct_admin'])"
                :to="{ name: 'Streams' }"
                :class="[
                  activeItem === 'Streams'
                    ? 'bg-yellow-800 text-white'
                    : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                  'group w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium relative',
                ]"
                :aria-current="activeItem === 'Streams' ? 'page' : undefined"
              >
                <VideoCameraIcon
                  :class="[
                    activeItem === 'Streams' ? 'text-yellow' : 'group-hover:text-yellow',
                    'h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                <span class="mt-2"
                  >Streams

                  <span
                    class="absolute bg-red-500 right-0 text-white rounded-full px-2 py-1"
                    style="top: -1px; padding-left: 7px"
                    v-if="$store.state.offlineStreamCount > 0"
                    >{{ $store.state.offlineStreamCount }}</span
                  >
                  <span
                    class="absolute bg-green-200 rounded-full top-0 right-0 p-1"
                    v-if="$store.state.offlineStreamCount === 0"
                  >
                    <CheckIcon class="w-4 h-4 text-white" />
                  </span>
                </span>
              </router-link>
              <router-link
                v-if="hasPermission(['pct_admin'])"
                :to="{ name: 'UnitValuesView' }"
                :class="[
                  activeItem === 'UnitValuesView'
                    ? 'bg-yellow-800 text-white'
                    : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                  'group w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium relative',
                ]"
                :aria-current="activeItem === 'ProjectConsole' ? 'page' : undefined"
              >
                <ChartPieIcon
                  :class="[
                    activeItem === 'UnitValuesView' ? 'text-yellow' : 'group-hover:text-yellow',
                    'h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                <span class="mt-2">Benchmarks</span>
              </router-link>
              <router-link
                v-if="hasPermission(['pct_admin'])"
                :to="{ name: 'Demo' }"
                :class="[
                  activeItem === 'Demo'
                    ? 'bg-yellow-800 text-white'
                    : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                  'group w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium relative',
                ]"
                :aria-current="activeItem === 'Demo' ? 'page' : undefined"
              >
                <ComputerDesktopIcon
                  :class="[
                    activeItem === 'Demo' ? 'text-yellow' : 'group-hover:text-yellow',
                    'h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                <span class="mt-2">Demo</span>
              </router-link>
              <router-link
                v-if="hasPermission(['pct_admin'])"
                :to="{ name: 'ProjectConsole' }"
                :class="[
                  activeItem === 'ProjectConsole'
                    ? 'bg-yellow-800 text-white'
                    : 'text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow',
                  'group w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium relative',
                ]"
                :aria-current="activeItem === 'ProjectConsole' ? 'page' : undefined"
              >
                <Cog8ToothIcon
                  :class="[
                    activeItem === 'ProjectConsole' ? 'text-yellow' : 'group-hover:text-yellow',
                    'h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                <span class="mt-2">Console</span>
              </router-link>
              <div
                class="cursor-pointer text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow group w-full p-3 rounded-md flex flex-col items-center text-center text-xs font-medium relative'"
                @click="isQuickSearchOpen = true"
                v-if="hasPermission('pct_admin')"
              >
                <MagnifyingGlassIcon class="w-6 h-6 group-hover:text-yellow" />
                <span class="mt-2">Search</span>
              </div>
              <div v-if="route.params.customer_name && hasPermission('pct_tracking_plan')">
                <hr class="my-4 h-0.5 w-full bg-oaiGray-300 border-0 dark:bg-gray-700" />
                <router-link
                  :class="[
                    activeItem === 'Planner' ? ' text-white' : 'text-oaiGray-50 hover:text-yellow',
                    'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium',
                  ]"
                  :to="{
                    name: 'Planner',
                    params: {
                      customer_name: `${route.params.customer_name}`,
                      site_id: `${route.params.site_id}`,
                    },
                  }"
                >
                  <AdjustmentsHorizontalIcon
                    :class="[
                      activeItem === 'Planner' ? 'text-yellow' : 'group-hover:text-yellow',
                      'h-6 w-6',
                    ]"
                  />
                  <span class="mt-2">Planner</span>
                </router-link>
              </div>
            </nav>
          </div>
          <div class="flex shrink-0 pb-5">
            <div
              @click="logout()"
              class="text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium cursor-pointer"
            >
              <component
                :is="account.icon"
                class="group-hover:text-yellow h-6 w-6"
                aria-hidden="true"
              />
              <span class="mt-2">{{ account.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:hidden shrink-0">
      <div class="flex items-center justify-between bg-oaiGray-600 py-2 px-4 sm:px-6 lg:px-8">
        <div>
          <button
            type="button"
            class="-mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md bg-oaigray-600 text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <router-link :to="{ name: 'ValidationPrdOverview' }">
          <img
            class="h-8 w-auto"
            src="../../assets/imgs/logo/icon_multi-white.png"
            alt="Your Company"
          />
        </router-link>
        <div>
          <div
            @click="logout()"
            class="text-oaiGray-50 hover:bg-yellow-800 hover:text-yellow group w-full px-3 rounded-md flex flex-col items-center text-xs font-medium cursor-pointer"
          >
            <component
              :is="account.icon"
              class="group-hover:text-yellow h-6 w-6"
              aria-hidden="true"
            />
            <span class="mt-2">{{ account.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <main class="flex-1 flex flex-col min-w-0 overflow-auto overscroll-none relative">
      <slot />
    </main>
  </div>
</template>

<script lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import {
  ArrowLeftOnRectangleIcon,
  CalendarDaysIcon,
  XMarkIcon,
  Bars3Icon,
  UserGroupIcon,
  Squares2X2Icon,
  ChartBarIcon,
  ComputerDesktopIcon,
  AdjustmentsHorizontalIcon,
  Cog6ToothIcon,
  CheckIcon,
  AdjustmentsVerticalIcon,
  VideoCameraIcon,
  Cog8ToothIcon,
  HomeModernIcon,
  MagnifyingGlassIcon,
  ChartPieIcon,
  CircleStackIcon,
  CpuChipIcon,
} from "@heroicons/vue/24/outline";
import { useQueryClient } from "@tanstack/vue-query";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import logger from "shared/services/logger";
import QuickSearch from "@/components/layout/QuickSearch.vue";
import { useProjectPlannerStats } from "@/composables/planner";
import AuthMixins from "@/mixins/AuthMixins";

type NavigationItem = {
  name: string;
  to: string;
  icon: unknown;
  current: boolean;
  items?: NavigationItem[];
  number?: number;
};

export default defineComponent({
  props: ["activeItem"],
  mixins: [AuthMixins],
  components: {
    QuickSearch,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverPanel,
    ArrowLeftOnRectangleIcon,
    CalendarDaysIcon,
    XMarkIcon,
    Bars3Icon,
    UserGroupIcon,
    ComputerDesktopIcon,
    Squares2X2Icon,
    ChartBarIcon,
    AdjustmentsHorizontalIcon,
    Cog6ToothIcon,
    CheckIcon,
    AdjustmentsVerticalIcon,
    VideoCameraIcon,
    Cog8ToothIcon,
    HomeModernIcon,
    MagnifyingGlassIcon,
    ChartPieIcon,
    CircleStackIcon,
    CpuChipIcon,
  },
  mounted() {
    if (this.hasPermission(["pct_invite_base", "pct_tracking_admin_prd_review"])) {
      this.navigation.push({
        name: "Users",
        to: "ProjectOverview",
        icon: UserGroupIcon,
        current: false,
      });
    }

    if (this.hasPermission(["pct_admin"])) {
      this.navigation.push({
        name: "CV",
        to: "",
        icon: CpuChipIcon,
        current: false,
        items: [
          { name: "Metrics", to: "Metrics", icon: ChartBarIcon, current: false },
          { name: "Person Dataset", to: "CVDataset", icon: CircleStackIcon, current: false },
        ],
      });
    }

    for (let i = 0; i < this.navigation.length; i++) {
      const childSelected = this.navigation[i].items?.find((item) => item.to == this.activeItem);

      if (this.navigation[i].name === this.activeItem || childSelected) {
        this.navigation[i].current = true;

        if (childSelected) {
          childSelected.current = true;
        }
      }
    }
    this.updateReadyToClose();
  },
  data() {
    return {
      navigation: [
        {
          name: "PRD",
          to: "ValidationPrdOverview",
          icon: CalendarDaysIcon,
          current: false,
        },
      ] as NavigationItem[],
      mobileMenuOpen: false,
      account: { name: "Logout", href: "#", icon: ArrowLeftOnRectangleIcon },
      isQuickSearchOpen: false,
    };
  },
  computed: {
    navigatorForMobile() {
      return this.navigation.flatMap((item) => {
        return item.items ? item.items : item;
      });
    },
  },
  methods: {
    async logout() {
      try {
        this.router.replace("/log-in");
        await this.signOut(this.queryClient);
      } catch (error) {
        logger.error("Unable to sign out", error as Error);
      }
    },
    openQuickSearchMobile() {
      this.mobileMenuOpen = false;
      setTimeout(() => {
        this.isQuickSearchOpen = true;
      }, 500);
    },
    updateReadyToClose() {
      const prdItem = this.navigation.find((item) => item.name === "PRD");
      if (prdItem) {
        prdItem.number =
          this.projectPlannerStats?.reduce(
            (acc, stat) => acc + stat.ready_to_close.filter((item) => !item.defer_until).length,
            0,
          ) || undefined;
      }
    },
  },
  setup() {
    const queryClient = useQueryClient();
    const router = useRouter();
    const route = useRoute();
    const { projectPlannerStats } = useProjectPlannerStats();
    return { queryClient, router, route, projectPlannerStats };
  },
  watch: {
    projectPlannerStats() {
      this.updateReadyToClose();
    },
  },
});
</script>
