<template>
  <div
    v-if="hasPermissions"
    style="
      display: flex;
      max-height: 100vh;
      max-width: 100vw;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    "
  >
    <img
      alt="image"
      :src="sequenceData?.frames?.[groupService?.frameIndex || 0]?.frame_url"
      style="display: none"
      ref="canvasBackgroundImage"
    />
    <div class="flex flex-1 flex-col">
      <TopBar :groupService="groupService" :query="route.query" />

      <div ref="canvasWrapper" class="flex-1">
        <canvas id="c"></canvas>
      </div>

      <FrameNavigator :groupService="groupService" :query="route.query" />
    </div>
    <Sidebar :canvasService="canvasService" :groupService="groupService" />
  </div>
  <ContextMenu
    v-if="canvasService && groupService"
    :canvasService="canvasService"
    :groupService="groupService"
  />
  <Crosshair v-if="canvasService" :canvasService="canvasService" />
  <img
    v-if="groupService?.isSequenceSaving"
    :src="saveBackground"
    class="fixed top-0 left-0 w-screen h-screen"
  />
</template>

<script lang="ts" setup>
import * as fabric from "fabric";
import { ref, onMounted, markRaw, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { useHasPermission } from "shared/composables/project";
import logger from "shared/services/logger";
import saveBackground from "@/assets/imgs/editorCursor.png";
import SequenceRepository from "@/repositories/SequenceRepository";
import { Sequence } from "@/views/person_gad/types";
import ContextMenu from "./components/ContextMenu.vue";
import Crosshair from "./components/Crosshair.vue";
import FrameNavigator from "./components/FrameNavigator.vue";
import Sidebar from "./components/Sidebar.vue";
import TopBar from "./components/TopBar.vue";
import CanvasService from "./services/canvasService";
import GroupService from "./services/groupService";

const route = useRoute();
const hasPermissions = useHasPermission("pct_tracking_person_gad");

const canvasObject = ref<fabric.Canvas>();
const canvasWrapper = ref<HTMLElement>();
const canvasBackgroundImage = ref<HTMLImageElement>();
const canvasService = ref<CanvasService>();
const groupService = ref<GroupService>();
const sequenceData = ref<Sequence>();
let prevWindowWidth = window.innerWidth;
let prevWindowHeight = window.innerHeight;

onMounted(async () => {
  sequenceData.value = await SequenceRepository.loadSequenceAnnotations(route.params.id as string);
  const backgroundImage = canvasBackgroundImage.value;
  if (!backgroundImage) {
    return;
  }

  backgroundImage.onload = () => {
    const isImageBroken = !backgroundImage.complete || backgroundImage.naturalWidth === 0;

    if (!isImageBroken) {
      initCanvas(backgroundImage);
    } else {
      const customerName = sequenceData.value?.customer_name;
      const siteId = sequenceData.value?.site_id;
      const cameraId = sequenceData.value?.camera_id;
      const date = sequenceData.value?.date;
      const frameIndex = groupService.value?.frameIndex || 0;
      const frameId = sequenceData.value?.frames?.[frameIndex]?.frame_id;

      logger.error(
        "Image is broken",
        `Image is broken for ${customerName}/${siteId}/${cameraId}/${date}/${frameId}`,
      );
    }
  };

  window.addEventListener("resize", handleWindowResize);
});

onUnmounted(async () => {
  if (groupService.value) {
    await groupService.value.saveAnnotations(undefined, true);
    groupService.value.dispose();
  }

  if (canvasService.value) {
    await canvasService.value.dispose();
  }

  window.removeEventListener("resize", handleWindowResize);
});

const initCanvas = async (image: HTMLImageElement) => {
  if (canvasService.value) {
    canvasService.value.rerender();
    return;
  }

  if (!sequenceData.value) {
    return;
  }

  const canvas = new fabric.Canvas("c", {
    width: canvasWrapper.value?.clientWidth || 0,
    height: canvasWrapper.value?.clientHeight || 0,
    backgroundColor: "#f3f3f3",
    data: {},
    preserveObjectStacking: true,
  });

  canvas.setCursor("pointer");

  const canvasServiceInstance = markRaw(new CanvasService(canvas));

  canvasServiceInstance.addMouseEventsFeature();
  canvasServiceInstance.addMouseWheelFeature();
  canvasServiceInstance.addContextMenuFeature();
  canvasServiceInstance.addKeyboardEventsFeatures();
  canvasServiceInstance.setCanvasSize(image);

  const groupServiceInstance = markRaw(new GroupService(canvasServiceInstance, sequenceData.value));
  groupServiceInstance.addKeyboardFeatures();

  canvasService.value = canvasServiceInstance;
  groupService.value = groupServiceInstance;
  canvasObject.value = markRaw(canvas);
};

const handleWindowResize = () => {
  if (!canvasObject.value || canvasObject.value.disposed) {
    return;
  }

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const diffWidth = windowWidth - prevWindowWidth;
  const diffHeight = windowHeight - prevWindowHeight;
  prevWindowWidth = windowWidth;
  prevWindowHeight = windowHeight;

  canvasObject.value.setDimensions({
    width: canvasObject.value.getWidth() + diffWidth,
    height: canvasObject.value.getHeight() + diffHeight,
  });
};
</script>
