<template>
  <MainLayout activeItem="PRD">
    <div class="divide-y divide-gray-200 flex-1 flex flex-col">
      <div class="py-5 px-4 bg-gray-50">
        <div class="grid grid-cols-3 items-center">
          <div class="col-span-1 flex">
            <router-link
              :to="backRoute"
              class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            >
              <ArrowLeftIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Back to overview
            </router-link>
          </div>
          <div class="col-span-1 flex justify-self-center">
            <button
              class="border-transparent inline-flex items-center px-4 py-1 text-sm font-medium"
              @click="priorDay()"
            >
              <ChevronLeftIcon class="h-7 w-7 text-oaiGray-300" aria-hidden="true" />
            </button>
            <h3 class="font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight sm:truncate">
              {{ typeof route.params.date === "string" && formatDateString(route.params.date) }}
            </h3>
            <button
              class="border-transparent inline-flex items-center px-4 py-1 text-sm font-medium"
              @click="nextDay()"
            >
              <ChevronRightIcon class="h-7 w-7 text-oaiGray-300" aria-hidden="true" />
            </button>
          </div>
          <div class="col-span-1 flex justify-end">
            <div
              v-if="!initialLoading"
              :class="`m-0.5 inline-flex items-center gap-x-1.5 rounded-xl px-3 py-1 text-xs font-medium ${aggregatedOpsReviewStatus}`"
            >
              {{ statusNameMap[aggregatedOpsReviewStatus] }}
            </div>
          </div>
        </div>
      </div>
      <div class="sm:hidden">
        <label for="cameras" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select
          id="tabs"
          name="tabs"
          class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
          @change="switchStreamDropdown($event)"
        >
          <option
            v-for="item in reviewStatusAllStreams"
            :key="item.camera_id"
            :selected="currentStreamCameraId === item.camera_id"
            :value="item.camera_id"
          >
            {{ item.displayed_name }}
          </option>
        </select>
      </div>
      <div class="hidden sm:flex flex-1 flex-col">
        <div class="border-b border-gray-200">
          <nav class="isolate flex divide-x divide-gray-200" aria-label="Tabs">
            <a
              v-for="(item, idx) in reviewStatusAllStreams"
              :key="item.camera_id"
              href="#"
              @click="switchStream(item.camera_id)"
              :class="[
                currentStreamCameraId === item.camera_id
                  ? 'text-gray-900'
                  : 'text-gray-500 hover:text-gray-700',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
                idx === 0 ? 'rounded-l-lg' : '',
                idx === reviewStatusAllStreams.length - 1 ? 'rounded-r-lg' : '',
                `w-1/${reviewStatusAllStreams.length}`,
              ]"
              :aria-current="currentStreamCameraId === item.camera_id ? 'page' : undefined"
              ><span class="py-4 px-1 pr-2">{{ item.displayed_name }}</span>
              <div
                v-if="!initialLoading"
                :class="`m-0.5 inline-flex items-center gap-x-1.5 rounded-xl px-3 py-1 text-xs font-medium ${getOpsReviewStatus(
                  item.camera_id,
                )}`"
              >
                {{ statusNameMap[getOpsReviewStatus(item.camera_id)] }}
              </div>

              <span
                aria-hidden="true"
                :class="[
                  currentStreamCameraId === item.camera_id ? 'bg-yellow-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5',
                ]"
              ></span>
            </a>
          </nav>
        </div>
        <LoadingSection :loading="loading" class="flex-1" />
        <div
          class="grid grid-cols-5 lg:grid-cols-10 pt-4 px-2"
          v-if="!loading && currentTimelapse && currentTimelapse.url"
        >
          <div class="col-span-5 lg:col-span-6">
            <div class="aspect-w-4 aspect-h-3">
              <DailyTimelapseVideoPlayer
                ref="timelapsePlayer"
                autoPlay
                keepControls
                :src="currentTimelapse?.url"
                :timestamps="currentTimelapse.timestamps"
                :milestones="dailyTimelapsePlayerProps.milestones"
                :processBoxes="dailyTimelapsePlayerProps.processBoxes"
                :highlightedBoxIndex="highlightedWorkingIntervalIndex"
                @play="handleReviewStartTime"
              />
            </div>
          </div>
          <div class="col-span-5 lg:col-span-4 p-2">
            <div class="flex justify-between">
              <div class="flex">
                <div class="flex gap-3 items-center">
                  <OaiListbox
                    :options="[
                      { value: 'latest', name: 'Latest' },
                      { value: 'first_level_review', name: 'First Level Review' },
                      { value: 'pipeline', name: 'Pipeline data' },
                    ]"
                    defaultSelected="latest"
                    @update:selected="switchProcessesMode"
                    :readonly="isEditing"
                  />

                  <div
                    v-if="currentStreamReviewStatus?.ops_reviewed"
                    class="flex items-center pl-2"
                  >
                    <div class="tooltip">
                      <InformationCircleIcon
                        class="h-5 w-5 text-yellow-600"
                      ></InformationCircleIcon>
                      <div class="tooltiptext tooltip-right text-xs text-left">
                        <span v-if="currentStreamReviewStatus.ops_reviewed">
                          <p>Last review:</p>
                          <p>{{ currentStreamReviewStatus.ops_reviewed_by }}</p>
                          <p>{{ formatIsoToDisplay(currentStreamReviewStatus.ops_reviewed) }}</p>
                        </span>
                        <span v-if="currentStreamReviewStatus.ops_checked">
                          <hr />
                          <p>Last check:</p>
                          <p>{{ currentStreamReviewStatus.ops_checked_by }}</p>
                          <p>{{ formatIsoToDisplay(currentStreamReviewStatus.ops_checked) }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center pl-2">
                    <div v-if="outages.length > 0" class="tooltip">
                      <VideoCameraSlashIcon class="h-5 w-5 text-red-800"></VideoCameraSlashIcon>
                      <div class="tooltiptext tooltip-right text-xs text-left">
                        <p>Outages:</p>
                        <p v-for="(outage, idx) in outages" :key="idx" class="mt-0">
                          #{{ idx + 1 }} - ({{ formatIsoToMinuteHour(outage.start) }} -
                          {{ formatIsoToMinuteHour(outage.end) }})
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="currentMode === 'latest'">
                <button
                  type="button"
                  v-if="!isEditing"
                  class="inline-flex items-center rounded-md border border-transparent bg-yellow-600 px-3 py-1.5 text-xs font-medium text-white shadow-sm"
                  @click="editProcesses"
                >
                  Edit
                </button>
                <div v-else class="flex gap-x-1.5">
                  <button
                    type="button"
                    class="inline-flex items-center rounded-md border border-transparent bg-gray-400 px-3 py-1.5 text-xs font-medium text-white shadow-sm"
                    @click="cancelEditProcesses"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="inline-flex items-center rounded-md px-3 py-1.5 text-xs font-medium text-white shadow-sm bg-green-400 hover:bg-green-600"
                    @click="saveData('review')"
                    :disabled="loadingApproveStage === 'review'"
                    v-if="
                      hasPermission([
                        'pct_tracking_admin_prd_review',
                        'pct_tracking_prd_review',
                        'pct_tracking_prd_check',
                      ])
                    "
                  >
                    <div class="pr-2" v-if="loadingApproveStage === 'review'">
                      <LoadingSpinner :cls="'text-white'" size="h-5 w-5" />
                    </div>
                    <span>Approve</span>
                  </button>
                  <button
                    type="button"
                    class="inline-flex items-center rounded-md px-3 py-1.5 text-xs font-medium text-white shadow-sm bg-purple-500 hover:bg-purple-600"
                    @click="saveData('check')"
                    :disabled="loadingApproveStage === 'check'"
                    v-if="hasPermission('pct_tracking_prd_check')"
                  >
                    <div class="pr-2" v-if="loadingApproveStage === 'check'">
                      <LoadingSpinner :cls="'text-white'" size="h-5 w-5" />
                    </div>
                    <span>Check</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-4 flex flex-col">
              <div class="">
                <div class="inline-block min-w-full py-2 align-middle">
                  <div class="md:rounded-lg">
                    <table
                      class="min-w-full divide-y divide-gray-300"
                      :class="isEditing ? 'editInput' : 'overviewInput'"
                    >
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Process
                          </th>
                          <th
                            scope="col"
                            class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Start
                          </th>
                          <th
                            scope="col"
                            class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            End
                          </th>
                          <th
                            scope="col"
                            class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            People
                          </th>
                          <th
                            scope="col"
                            class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
                          ></th>
                          <th
                            scope="col"
                            class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
                          ></th>
                          <th
                            scope="col"
                            class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Location
                          </th>
                          <th scope="col" class="relative py-3.5 px-2">
                            <span class="sr-only">Location</span>
                          </th>
                          <th scope="col" class="relative py-3.5 px-2" v-if="isEditing">
                            <span class="sr-only">Delete</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white align-middle">
                        <template v-for="(process, idx) in processes" :key="process._id">
                          <tr
                            class="border-t hover:bg-gray-100"
                            :class="[route.query.process_id === process._id ? 'bg-yellow-100' : '']"
                            @mouseenter="highlightWorkInterval(idx, 0)"
                            @mouseleave="dropHighlightWorkInterval()"
                          >
                            <td
                              class="w-2/6 whitespace-nowrap py-2 px-1 text-sm font-medium text-gray-900 sm:pl-4"
                            >
                              <SearchList
                                :defaultOptions="decodedProcessLabels"
                                :editMode="isEditing"
                                :validationError="validationError"
                                :selectedValue="process.decoded_label"
                                @updateEvent="handleSelectProcess($event, process)"
                                bgClass="bg-transparent"
                              />
                            </td>
                            <td class="w-1/6 whitespace-nowrap px-1 py-2 text-sm text-gray-500">
                              <SearchList
                                :defaultOptions="timeInputs"
                                :editMode="isEditing"
                                :clock="true"
                                :validationError="validationError"
                                :selectedValue="
                                  process.work_intervals[0].start_time
                                    ? formatIsoToMinuteHour(process.work_intervals[0].start_time)
                                    : ''
                                "
                                @updateEvent="
                                  updateWorkIntervalTime(process, 0, 'start_time', $event)
                                "
                                bgClass="bg-transparent"
                              />
                            </td>
                            <td class="w-1/6 whitespace-nowrap px-1 py-2 text-sm text-gray-500">
                              <search-list
                                :defaultOptions="timeInputs"
                                :editMode="isEditing"
                                :clock="true"
                                :validationError="validationError"
                                :errorMessage="errorMessage"
                                :selectedValue="
                                  process.work_intervals[0].end_time
                                    ? formatIsoToMinuteHour(process.work_intervals[0].end_time)
                                    : ''
                                "
                                @updateEvent="
                                  updateWorkIntervalTime(process, 0, 'end_time', $event)
                                "
                                bgClass="bg-transparent"
                                :isError="
                                  isEndTimePrior(
                                    process.work_intervals[0].start_time,
                                    process.work_intervals[0].end_time,
                                  )
                                "
                              />
                            </td>
                            <td
                              class="relative whitespace-nowrap py-2 px-1 text-sm font-medium text-gray-500"
                            >
                              <input
                                type="number"
                                min="0"
                                :value="process.work_intervals[0].workforce.validated_count"
                                @change="handlePeopleCountChange($event, process, 0)"
                                v-if="isEditing"
                                class="w-16 text-sm"
                                @keypress="handlePeopleCountKeyPress"
                              />
                              <span v-else>
                                {{ process.work_intervals[0].workforce.validated_count }}
                              </span>
                            </td>
                            <td
                              class="cursor-pointer"
                              @click="jumpToVideoTime(process.work_intervals[0].start_time)"
                            >
                              <PlayIcon class="h-4 w-4" />
                            </td>
                            <td>
                              <div class="flex items-center">
                                <div>&#8203;</div>
                                <button
                                  v-if="isEditing"
                                  class="text-gray-500 hover:text-yellow-800 pl-1"
                                  @click="
                                    process.work_intervals.push({
                                      start_time: '',
                                      end_time: '',
                                      workforce: getEmptyWorkforce(),
                                    })
                                  "
                                >
                                  <PlusIcon class="w-5" />
                                </button>
                              </div>
                            </td>
                            <td
                              class="whitespace-nowrap px-1 text-xs pl-2 cursor-pointer"
                              @click="openLocationModal(process)"
                            >
                              <OaiTooltip position="top">
                                <div v-if="process.section_mask_mapping.level_id">
                                  <div class="w-32 font-semibold">
                                    {{
                                      getDisplayedLevelText(process.section_mask_mapping.level_id)
                                    }}
                                  </div>
                                  <div
                                    class="w-32 truncate"
                                    v-if="
                                      process.section_mask_mapping.id &&
                                      sectionMaskMap[process.section_mask_mapping.id]
                                    "
                                  >
                                    {{
                                      getDisplayedSectionMaskText(
                                        sectionMaskMap[process.section_mask_mapping.id],
                                      )
                                    }}
                                  </div>
                                  <div class="text-yellow-700" v-else>(no section mapping)</div>
                                </div>
                                <div v-else>-</div>
                                <template #tooltip>
                                  <div class="text-xs">
                                    <div class="font-semibold">
                                      {{
                                        getDisplayedLevelText(process.section_mask_mapping.level_id)
                                      }}
                                    </div>
                                    <div
                                      v-if="
                                        process.section_mask_mapping.id &&
                                        sectionMaskMap[process.section_mask_mapping.id]
                                      "
                                    >
                                      {{
                                        getDisplayedSectionMaskText(
                                          sectionMaskMap[process.section_mask_mapping.id],
                                        )
                                      }}
                                    </div>
                                  </div>
                                </template>
                              </OaiTooltip>
                            </td>

                            <td class="whitespace-nowrap py-2 px-1">
                              <div class="flex items-center">
                                <div>&#8203;</div>
                                <div v-if="process.location.length > 0">
                                  <CheckIcon class="w-6 text-green-500" />
                                </div>
                                <XMarkIcon v-else class="w-6 text-red-500" />
                                <button
                                  class="text-oaiGray-600 hover:text-yellow-900 underline pl-2"
                                  v-if="isEditing"
                                  @click="openLocationModal(process)"
                                >
                                  <PencilIcon class="h-4 w-4"></PencilIcon>
                                </button>
                              </div>

                              <small
                                v-if="process.location.length === 0 && validationError"
                                class="text-red text-xs"
                                >No box</small
                              >
                              <small
                                v-else-if="hasInvalidBbox(process) && validationError"
                                class="text-red text-xs"
                                >Invalid box</small
                              >
                              <small
                                v-else-if="
                                  !process.section_mask_mapping.level_id && validationError
                                "
                                class="text-red text-xs"
                                >No level</small
                              >
                            </td>
                            <td
                              class="relative whitespace-nowrap py-2 px-1 text-right text-sm font-medium"
                              v-if="isEditing"
                            >
                              <div class="flex items-center justify-end pr-2">
                                <div>&#8203;</div>
                                <button
                                  class="w-5 text-gray-500 hover:text-red-800"
                                  v-if="idxProcessDelete !== idx || !confirmProcessDelete"
                                  @click="
                                    confirmProcessDelete = true;
                                    idxProcessDelete = idx;
                                  "
                                >
                                  <TrashIcon />
                                </button>
                                <button
                                  class="w-6 text-gray-500 hover:text-yellow-800"
                                  v-if="confirmProcessDelete && idxProcessDelete === idx"
                                  @click="confirmProcessDelete = false"
                                >
                                  <XMarkIcon />
                                </button>
                                <button
                                  type="button"
                                  v-if="confirmProcessDelete && idxProcessDelete === idx"
                                  class="w-6 text-orange-500 hover:text-orange-800"
                                  @click="removeProcess(idx)"
                                >
                                  <CheckCircleIcon />
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              isEndTimePrior(
                                process.work_intervals[0].start_time,
                                process.work_intervals[0].end_time,
                              )
                            "
                          >
                            <td
                              colspan="8"
                              class="text-red-600 text-center py-1 mx-4 hover:bg-white"
                            >
                              <small>End time is prior to start time</small>
                            </td>
                          </tr>

                          <tr
                            v-for="(workInterval, intervalIndex) in process.work_intervals.slice(1)"
                            :key="intervalIndex"
                            class="border-t border-gray-200 hover:bg-gray-100"
                            :class="[route.query.process_id === process._id ? 'bg-yellow-100' : '']"
                            @mouseenter="highlightWorkInterval(idx, intervalIndex + 1)"
                            @mouseleave="dropHighlightWorkInterval()"
                          >
                            <td
                              class="whitespace-nowrap py-4 px-1 text-xs font text-gray-900 text-right pr-2"
                            >
                              <span class="bg-gray-100 rounded-full p-2"
                                >Work interval #{{ intervalIndex + 2 }}:</span
                              >
                            </td>
                            <td class="w-1/6 whitespace-nowrap px-1 py-4 text-sm text-gray-500">
                              <search-list
                                :defaultOptions="timeInputs"
                                :editMode="isEditing"
                                :clock="true"
                                :validationError="validationError"
                                :selectedValue="
                                  workInterval.start_time
                                    ? formatIsoToMinuteHour(workInterval.start_time)
                                    : ''
                                "
                                @updateEvent="
                                  workInterval.start_time = setMinuteHourForIso(
                                    $event,
                                    workInterval.start_time,
                                  )
                                "
                                bgClass="bg-transparent"
                              />
                            </td>
                            <td class="w-1/6 whitespace-nowrap px-1 py-4 text-sm text-gray-500">
                              <search-list
                                :defaultOptions="timeInputs"
                                :editMode="isEditing"
                                :clock="true"
                                :validationError="validationError"
                                :errorMessage="errorMessage"
                                :selectedValue="
                                  workInterval.end_time
                                    ? formatIsoToMinuteHour(workInterval.end_time)
                                    : ''
                                "
                                @updateEvent="
                                  workInterval.end_time = setMinuteHourForIso(
                                    $event,
                                    workInterval.end_time,
                                  )
                                "
                                bgClass="bg-transparent"
                              />
                              <p
                                v-if="
                                  workInterval.start_time != '' &&
                                  workInterval.end_time != '' &&
                                  workInterval.start_time >= workInterval.end_time
                                "
                                class="text-red text-ellipsis"
                              >
                                <small>End time prior to start time</small>
                              </p>
                              <p
                                v-if="
                                  workInterval.start_time &&
                                  workInterval.end_time &&
                                  process.work_intervals.some(
                                    (item, index) =>
                                      intervalIndex + 1 !== index &&
                                      ((workInterval.start_time >= item.start_time &&
                                        workInterval.start_time < item.end_time) ||
                                        (workInterval.end_time <= item.end_time &&
                                          workInterval.end_time > item.start_time)),
                                  )
                                "
                                class="text-red text-ellipsis"
                              >
                                <small>Work interval overlap</small>
                              </p>
                            </td>
                            <td
                              class="relative whitespace-nowrap py-4 px-1 text-sm font-medium text-gray-500"
                            >
                              <input
                                type="number"
                                min="0"
                                :value="
                                  process.work_intervals[intervalIndex + 1].workforce
                                    .validated_count
                                "
                                @change="
                                  handlePeopleCountChange($event, process, intervalIndex + 1)
                                "
                                v-if="isEditing"
                                class="w-16 text-sm"
                                @keypress="handlePeopleCountKeyPress"
                              />
                              <span v-else>
                                {{
                                  process.work_intervals[intervalIndex + 1].workforce
                                    .validated_count
                                }}
                              </span>
                            </td>

                            <td
                              class="cursor-pointer"
                              @click="
                                jumpToVideoTime(
                                  process.work_intervals[intervalIndex + 1].start_time,
                                )
                              "
                            >
                              <PlayIcon class="h-4 w-4" />
                            </td>

                            <td
                              class="relative whitespace-nowrap py-4 px-1 text-right text-sm font-medium"
                            >
                              <div class="flex items-center">
                                <div>&#8203;</div>
                                <button
                                  v-if="isEditing"
                                  class="w-5 text-gray-500 hover:text-red-800"
                                  @click="process.work_intervals.splice(intervalIndex + 1, 1)"
                                >
                                  <XMarkIcon />
                                </button>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div class="flex justify-between">
                    <div class="pl-2 mt-4">
                      <button v-if="isEditing || processes.length == 0">
                        <PlusIcon
                          class="h-10 w-10 p-2 shadow-sm border-2 border-yellow-600 rounded-full text-yellow-700 hover:bg-yellow-600 hover:text-white focus:outline-none"
                          @click="
                            editProcesses();
                            addProcess();
                          "
                        ></PlusIcon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LocationMapping
      v-if="currentProcess"
      :key="currentProcess._id"
      :open="locationMappingModalOpen"
      :sectionMasks="filteredSectionMasks"
      :process="currentProcess"
      :tagMap="tagMap"
      :formatTagText="formatTagText"
      :getDisplayedSectionMaskText="getDisplayedSectionMaskText"
      :readonly="!isEditing"
      @closeModal="closeLocationMappingModal()"
      @updateProcess="updateProcessLocation($event)"
    />
  </MainLayout>
</template>

<script lang="ts">
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  VideoCameraSlashIcon,
  PlusIcon,
  CheckIcon,
  PencilIcon,
  XMarkIcon,
  TrashIcon,
  CheckCircleIcon,
} from "@heroicons/vue/20/solid";
import { PlayIcon } from "@heroicons/vue/24/outline";
import {
  format,
  addDays,
  parseISO,
  subDays,
  setMinutes,
  setHours,
  startOfDay,
  isEqual,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import { v4 as uuidv4 } from "uuid";
import { defineComponent } from "vue";
import { RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import DailyTimelapseVideoPlayer from "shared/components/camera/DailyTimelapseVideoPlayer.vue";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { getDailyTimelapseMilestonesAndProcessBoxes } from "shared/composables/camera";
import { useSaveBeforeLeaveConfirmationModal } from "shared/composables/toast";
import CameraRepository from "shared/repositories/CameraRepository";
import HierarchyRepository from "shared/repositories/HierarchyRepository";
import SectionMasksRepository from "shared/repositories/SectionMasksRepository";
import dateService from "shared/services/dateService";
import logger from "shared/services/logger";
import { StreamOutage, DailyTimelapseUrl } from "shared/types/Camera";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import { BaseProcess, ProcessWorkInterval } from "shared/types/Process";
import { DecodedLabel, EncodedLabel } from "shared/types/ProcessClass";
import { SectionMask } from "shared/types/SectionMask";
import MainLayout from "@/components/layout/MainLayout.vue";
import SearchList from "@/components/other/SearchList.vue";
import { useProcessClasses } from "@/composables/process";
import ValidationRepository from "@/repositories/ValidationRepository";
import { timeInputs } from "@/services/timeDateFormat";
import { HierarchyTagSplit } from "@/types/HierarchyTag";
import {
  OpsReviewDuration,
  ReviewProcess,
  ReviewStatus,
  ValidationStage,
} from "@/types/Validation";
import LocationMapping from "@/views/process_validation/components/LocationMapping.vue";

const formatUtcDate = (date: Date): string =>
  fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

export default defineComponent({
  name: "ValidationPrdDate",
  components: {
    MainLayout,
    DailyTimelapseVideoPlayer,
    LoadingSpinner,
    LoadingSection,
    SearchList,
    LocationMapping,
    OaiTooltip,
    ArrowLeftIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    InformationCircleIcon,
    VideoCameraSlashIcon,
    PlusIcon,
    CheckIcon,
    PencilIcon,
    XMarkIcon,
    TrashIcon,
    CheckCircleIcon,
    OaiListbox,
    PlayIcon,
  },
  mounted() {
    this.initializeValidationDataForStream(true);
  },
  beforeRouteUpdate(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: CallableFunction,
  ) {
    if (!this.isEditing) {
      next();
    } else {
      this.toRoute = to.fullPath;
      this.showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
        if (confirmed) {
          this.leaveRoute();
        }
      });
    }
  },
  beforeRouteLeave(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: CallableFunction,
  ) {
    if (!this.isEditing) {
      next();
    } else {
      this.toRoute = to.fullPath;
      this.showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
        if (confirmed) {
          this.leaveRoute();
        }
      });
    }
  },

  data() {
    return {
      timeInputs: timeInputs,
      toSwitchDay: "" as string,
      initialLoading: true as boolean,
      loading: true as boolean,
      loadingApproveStage: null as ValidationStage | null,
      isEditing: false as boolean,
      locationMappingModalOpen: false as boolean,
      toRoute: "" as string,
      hierarchyTags: [] as HierarchyTagStore[],
      sectionMasks: [] as SectionMask[],
      toSwitchStream: null as string | null,
      pipelineData: [] as BaseProcess[],
      opsReviewModeProcesses: [] as ReviewProcess[],
      opsReviewProcesses: [] as ReviewProcess[],
      processes: [] as ReviewProcess[],
      currentProcess: null as ReviewProcess | null,
      timelapses: [] as DailyTimelapseUrl[],
      outages: [] as StreamOutage[],
      editMode: false as boolean,
      reviewStatusAllStreams: [] as ReviewStatus[],
      validationError: false as boolean,
      errorMessage: "" as string,
      idxProcessDelete: -1 as number,
      confirmProcessDelete: false as boolean,
      startTimeEdit: null as Date | null,
      currentMode: "latest" as "latest" | "first_level_review" | "pipeline",
      highlightedWorkingIntervalIndex: undefined as number | undefined,
    };
  },
  watch: {
    currentStreamCameraId(newValue, oldValue) {
      if (newValue && oldValue !== "") {
        this.initializeValidationDataForStream();
      }
    },
  },
  computed: {
    backRoute() {
      return this.route.query.back_route
        ? JSON.parse(this.route.query.back_route as string)
        : {
            name: "ValidationPrdOverview",
            query: { start: this.startOfWeekDate, end: this.endOfWeekDate },
          };
    },

    currentStreamCameraId() {
      return (this.route.query.camera_id as string) || "";
    },
    currentTimelapse(): DailyTimelapseUrl | null {
      const timelapse = this.timelapses.find(
        (item) => item.camera_id === this.currentStreamCameraId,
      );
      return timelapse ? timelapse : null;
    },
    currentStreamReviewStatus() {
      if (this.currentStreamCameraId) {
        return this.reviewStatusAllStreams.find(
          (item) => item.camera_id === this.currentStreamCameraId,
        );
      }
      return null;
    },
    dailyTimelapsePlayerProps() {
      const mappedProcesses = this.processes.map((process) => {
        const start_time = process.work_intervals.reduce((acc, curr) => {
          if (curr.start_time && curr.start_time < acc) {
            return curr.start_time;
          }
          return acc;
        }, process.work_intervals[0].start_time);

        return {
          start_time: dateService.parseLocalDate(start_time),
          encoded_label: process.encoded_label,
          location: process.location,
          work_intervals: process.work_intervals.map((workInterval) => ({
            start_time: dateService.parseLocalDate(workInterval.start_time),
            end_time: dateService.parseLocalDate(workInterval.end_time),
          })),
        };
      });

      return getDailyTimelapseMilestonesAndProcessBoxes(mappedProcesses, true);
    },
    aggregatedOpsReviewStatus() {
      const status_set = new Set(
        this.reviewStatusAllStreams.map((item) => this.getOpsReviewStatus(item.camera_id)),
      );
      if (
        status_set.has("ready_to_review") &&
        !status_set.has("reviewed") &&
        !status_set.has("reviewed_and_checked")
      ) {
        return "ready_to_review";
      }
      if (
        status_set.has("ready_to_review") &&
        (status_set.has("reviewed") || status_set.has("reviewed_and_checked"))
      ) {
        return "partially_reviewed";
      }
      if (status_set.has("reviewed") && !status_set.has("ready_to_review")) {
        return "reviewed";
      }
      if (status_set.has("reviewed_and_checked") && !status_set.has("ready_to_review")) {
        return "reviewed_and_checked";
      }
      if (status_set.has("no_data") && status_set.size === 1) {
        return "no_data";
      }
      if (status_set.has("no_data_possible")) {
        return "no_data_possible";
      }
      return "unknown_status";
    },
    statusNameMap() {
      return {
        ready_to_review: "Ready",
        reviewed: "Reviewed",
        reviewed_and_checked: "Reviewed + Checked",
        partially_reviewed: "Partially reviewed",
        no_data: "No Data",
        no_data_possible: "No Data possible",
        no_section_masks: "No Masks",
        unknown_status: "Unknown Status",
      };
    },
    decodedProcessLabels() {
      return this.processClasses
        .map((item) => item.decodedLabel)
        .sort((a, b) => a.localeCompare(b));
    },
    processLabelMap() {
      return this.processClasses.reduce((acc, item) => {
        acc[item.encodedLabel] = item.decodedLabel;
        return acc;
      }, {} as Record<string, DecodedLabel>);
    },
    filteredSectionMasks() {
      return this.sectionMasks.filter((item) => item.camera_id === this.currentStreamCameraId);
    },
    tagMap() {
      return this.hierarchyTags.reduce(
        (
          dict: Record<string, HierarchyTagStore & Partial<HierarchyTagSplit>>,
          item: HierarchyTagStore,
        ) => {
          dict[item._id] = item;

          if (item.splits) {
            item.splits.forEach((split) => {
              if (!split.id) {
                return;
              }

              dict[split.id] = {
                ...item,
                ...split,
                name: `${item.name} - ${split.name}`,
                _id: split.id,
                splits: null,
              };
            });
          }
          return dict;
        },
        {},
      );
    },
    sectionMaskMap() {
      return this.sectionMasks.reduce((dict: Record<string, SectionMask>, item: SectionMask) => {
        dict[item._id] = item;
        return dict;
      }, {});
    },
    startOfWeekDate() {
      return format(
        startOfWeek(parseISO(this.route.params.date as string), { weekStartsOn: 1 }),
        "yyyy-MM-dd",
      );
    },
    endOfWeekDate() {
      return format(
        endOfWeek(parseISO(this.route.params.date as string), { weekStartsOn: 1 }),
        "yyyy-MM-dd",
      );
    },
  },
  methods: {
    isEndTimePrior(start: string, end: string) {
      return start !== "" && end !== "" && start >= end;
    },
    async initializeValidationDataForStream(initial = false) {
      this.processes = [];
      this.loading = true;
      await Promise.all([
        this.loadReviewStatus(),
        this.loadSectionMasks(),
        this.loadHierarchyData(),
        this.loadDailyTimelapses(),
      ]);

      if (initial && this.reviewStatusAllStreams.length > 0 && !this.currentStreamCameraId) {
        this.router.replace({
          query: { ...this.route.query, camera_id: this.reviewStatusAllStreams[0].camera_id },
        });
      }

      if (this.route.params.customer_name && this.route.params.site_id && this.route.params.date) {
        await Promise.all([
          this.loadPipelineData(),
          this.loadOpsReviewData(),
          this.loadOutages(),
          this.loadOpsReviewModeData(),
        ]);
      } else {
        // it's unclear why we end up here
        logger.error("Unable to load validation prd data due to missing URL parameters");
      }

      this.setProcesses();
      this.loading = false;
      if (initial) {
        this.initialLoading = false;
      }
    },
    setProcesses() {
      if (this.currentStreamReviewStatus?.ops_reviewed) {
        this.processes = structuredClone(this.opsReviewProcesses);
      } else {
        this.setPipelineProcesses();
      }
    },
    roundNearest15Minutes(isoTimestamp: string): string {
      const date = parseISO(isoTimestamp);
      const ms = 1000 * 60 * 15;
      const roundedDate = new Date(Math.round(date.getTime() / ms) * ms);
      return format(roundedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS+00:00");
    },
    copyOpsProcesses() {
      this.processes = structuredClone(this.opsReviewProcesses);
      this.editProcesses();
    },
    toggleSaveBeforeLeaveModal() {
      this.toRoute = "";
      this.toSwitchDay = "";
      this.toSwitchStream = null;
    },
    leaveRoute() {
      this.isEditing = false;
      if (this.toRoute != "") {
        this.router.push(this.toRoute);
      }
      if (this.toSwitchStream) {
        this.router.replace({ query: { ...this.route.query, camera_id: this.toSwitchStream } });
      }
      if (this.toSwitchDay != "") {
        if (this.toSwitchDay == "prior") {
          this.priorDay();
        }
        if (this.toSwitchDay == "next") {
          this.nextDay();
        }
      }
      this.toggleSaveBeforeLeaveModal();
    },
    loadSectionMasks() {
      const { customer_name, site_id } = this.route.params;
      return SectionMasksRepository.loadValidSectionMasks(
        customer_name as string,
        site_id as string,
      )
        .then((data) => {
          this.sectionMasks = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load section masks");
          }
        });
    },
    loadHierarchyData() {
      const { customer_name, site_id } = this.route.params;
      return HierarchyRepository.loadHierarchyTags(customer_name as string, site_id as string)
        .then((data) => {
          this.hierarchyTags = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to retrieve hierarchy data");
          }
        });
    },
    loadPipelineData() {
      const { customer_name, site_id, date } = this.route.params;
      return ValidationRepository.loadPrdPipelineData(
        customer_name as string,
        site_id as string,
        this.currentStreamCameraId as string,
        date as string,
      )
        .then((data) => {
          this.pipelineData = this.addDecodedLabelToProcesses(data);
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load pipeline data");
          }
        });
    },
    addDecodedLabelToProcesses(incomingProcesses: ReviewProcess[]) {
      return incomingProcesses.map((obj) => ({
        ...obj,
        decoded_label:
          obj.encoded_label !== null
            ? this.processLabelMap[obj.encoded_label]
            : ("" as DecodedLabel),
      }));
    },
    loadOpsReviewData() {
      const { customer_name, site_id, date } = this.route.params;
      return ValidationRepository.loadOpsReviewCameraDay(
        customer_name as string,
        site_id as string,
        this.currentStreamCameraId as string,
        date as string,
      )
        .then((data) => {
          this.opsReviewProcesses = this.addDecodedLabelToProcesses(data);
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load ops review data");
          }
        });
    },
    loadOpsReviewModeData() {
      const { customer_name, site_id, date } = this.route.params;
      return ValidationRepository.loadOpsReviewProcessesFirstLevelReview(
        customer_name as string,
        site_id as string,
        this.currentStreamCameraId as string,
        date as string,
      )
        .then((data) => {
          this.opsReviewModeProcesses = this.addDecodedLabelToProcesses(data);
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load ops review data");
          }
        });
    },
    handleSelectProcess(selectedValue: DecodedLabel, process: ReviewProcess) {
      const prevEncodedLabel = process.encoded_label;
      const newEncodedLabel = this.getEncodedLabel(selectedValue);

      process.decoded_label = selectedValue;
      process.encoded_label = newEncodedLabel;

      if (!prevEncodedLabel) {
        return;
      }

      const tag = this.tagMap[process.section_mask_mapping.level_id as string];
      const selectedTagSplit = tag?.splits?.find((split) =>
        split.processes.includes(prevEncodedLabel),
      );

      if (selectedTagSplit && !selectedTagSplit.processes.includes(newEncodedLabel)) {
        process.section_mask_mapping.id = null;
        process.section_mask_mapping.level_id = null;
        process.section_mask_mapping.level_name = undefined;

        this.showToast(
          "warning",
          "Incompatible switch of process class (new class does not match previous split). Please reassign the the location.",
        );
      }
    },
    saveOpsReviewData(
      processesToUpdate: ReviewProcess[],
      stage: ValidationStage,
      startEdit: Date,
      endEdit: Date,
    ) {
      const { customer_name, site_id, date } = this.route.params;
      const opsReviewDuration = {
        start_time_edit: startEdit.toISOString().replace("Z", "+00:00"),
        end_time_edit: endEdit.toISOString().replace("Z", "+00:00"),
      } as OpsReviewDuration<string>;

      return ValidationRepository.saveOpsReviewData(
        customer_name as string,
        site_id as string,
        this.currentStreamCameraId,
        date as string,
        stage as string,
        processesToUpdate,
        opsReviewDuration,
      )
        .then((data) => {
          this.opsReviewProcesses = this.addDecodedLabelToProcesses(data);
          this.processes = this.opsReviewProcesses;
          this.loadReviewStatus();
          this.validationError = false;
          this.isEditing = false;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to save processes.");
          }
        });
    },
    loadReviewStatus() {
      const { customer_name, site_id, date } = this.route.params;
      return ValidationRepository.loadReviewStatusSite(
        customer_name as string,
        site_id as string,
        date as string,
      )
        .then((data) => {
          this.reviewStatusAllStreams = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load ops review data");
          }
        });
    },
    loadDailyTimelapses() {
      const { customer_name, site_id, date } = this.route.params;
      return CameraRepository.loadDailyTimelapseUrlsForDate(
        customer_name as string,
        site_id as string,
        date as string,
      )
        .then((data) => {
          this.timelapses = data;
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load timelapse.");
          }
        });
    },
    loadOutages() {
      const { customer_name, site_id, date } = this.route.params;
      return CameraRepository.loadDailyOutages(
        customer_name as string,
        site_id as string,
        this.currentStreamCameraId as string,
        date as string,
      )
        .then((data) => {
          this.outages = data || [];
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    getOpsReviewStatus(camera_id: string) {
      const { date } = this.route.params;

      const dateObj = new Date(date as string);
      if (dateObj.getDay() === 0 || dateObj > new Date()) {
        return "no_data_possible";
      }

      const status = this.reviewStatusAllStreams.find((item) => item.camera_id === camera_id);
      if (status) {
        if (!this.timelapses.find((item) => item.camera_id === camera_id)?.url) {
          if (isEqual(startOfDay(dateObj), startOfDay(new Date()))) {
            return "no_data_possible";
          }
          return "no_data";
        }
        if (this.sectionMasks.filter((item) => item.camera_id === camera_id).length === 0) {
          return "no_section_masks";
        }
        if (!status.ops_reviewed) {
          return "ready_to_review";
        }
        if (status.ops_reviewed && !status.ops_checked) {
          return "reviewed";
        }
        if (status.ops_reviewed && status.ops_checked) {
          return "reviewed_and_checked";
        }
      }

      return "unknown_status";
    },
    switchStreamDropdown(event: Event) {
      const stream = this.reviewStatusAllStreams.find(
        (item) => (event.target as HTMLSelectElement).value === item.camera_id,
      );
      if (stream) {
        this.switchStream(stream.camera_id);
      }
    },
    switchStream(camera_id: string) {
      if (this.isEditing) {
        this.toSwitchStream = camera_id;
      } else {
        this.startTimeEdit = null;
        this.router.replace({ query: { ...this.route.query, camera_id } });
      }
    },
    priorDay() {
      if (this.isEditing) {
        this.showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
          if (confirmed) {
            this.leaveRoute();
          }
        });
        this.toSwitchDay = "prior";
      } else {
        const priorDay = format(
          subDays(parseISO(this.route.params.date as string), 1),
          "yyyy-MM-dd",
        );
        this.router.push({
          name: "ValidationPrdDate",
          params: {
            customer_name: this.route.params.customer_name,
            site_id: this.route.params.site_id,
            date: priorDay,
          },
          query: {
            mode: this.route.query.mode,
            camera_id: this.route.query.camera_id,
          },
        });
      }
    },
    nextDay() {
      if (this.isEditing) {
        this.showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
          if (confirmed) {
            this.leaveRoute();
          }
        });
        this.toSwitchDay = "next";
      } else {
        const nextDay = format(
          addDays(parseISO(this.route.params.date as string), 1),
          "yyyy-MM-dd",
        );
        this.router.push({
          name: "ValidationPrdDate",
          params: {
            customer_name: this.route.params.customer_name,
            site_id: this.route.params.site_id,
            date: nextDay,
          },
          query: {
            mode: this.route.query.mode,
            camera_id: this.route.query.camera_id,
          },
        });
      }
    },
    formatDateString(dateString: string): string {
      return format(parseISO(dateString), "dd.MM.yyyy");
    },
    formatIsoToMinuteHour(isoString: string) {
      return format(parseUtcDate(isoString), "HH:mm");
    },
    formatIsoToDisplay(isoString: string) {
      return format(parseISO(isoString), "dd.MM.yy, HH:mm:ss");
    },
    capitalizeSectionType(name: HierarchyType) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    formatTagText(tag: HierarchyTagStore) {
      return tag.name ? `${tag.name}` : `${this.capitalizeSectionType(tag.type)} (${tag.number})`;
    },
    getDisplayedSectionMaskText(sectionMask: SectionMask, renderLevelName = false) {
      const tags: HierarchyTagStore[] = [];
      if (sectionMask.building_id) {
        tags.push(this.tagMap[sectionMask.building_id]);
      }
      if (renderLevelName && sectionMask.level_id) {
        tags.push(this.tagMap[sectionMask.level_id]);
      }
      if (sectionMask.section_id) {
        tags.push(this.tagMap[sectionMask.section_id]);
      }
      const tagNames = tags.filter((tag) => tag).map((obj) => this.formatTagText(obj));
      return tagNames.join(" | ");
    },
    getDisplayedLevelText(levelId: string | null) {
      return levelId && levelId in this.tagMap ? this.formatTagText(this.tagMap[levelId]) : "";
    },
    setMinuteHourForIso(time: string, iso_string: string) {
      try {
        const [hours, minutes] = time.split(":");
        if (!iso_string) {
          iso_string = new Date(this.route.params.date as string).toISOString();
        }
        return formatUtcDate(
          setMinutes(setHours(parseISO(iso_string), parseInt(hours)), parseInt(minutes)),
        );
      } catch {
        return "";
      }
    },
    getEncodedLabel(decodedLabel: DecodedLabel) {
      return this.processClasses.find((item) => item.decodedLabel === decodedLabel)
        ?.encodedLabel as EncodedLabel;
    },
    editProcesses() {
      this.handleReviewStartTime();
      this.isEditing = true;
    },
    cancelEditProcesses() {
      this.processes = [];
      this.setProcesses();
      this.validationError = false;
      this.isEditing = false;
    },
    getEmptyWorkforce() {
      return {
        validated_count: null,
        predicted_count: null,
      };
    },
    addProcess() {
      const { customer_name, site_id, date } = this.route.params;
      this.processes.push({
        _id: uuidv4(),
        customer_name: customer_name as string,
        site_id: site_id as string,
        camera_id: this.currentStreamCameraId,
        date: date as string,
        start_time: "",
        end_time: "",
        location: [],
        decoded_label: "",
        encoded_label: null,
        section_mask_mapping: {
          id: null,
          level_id: null,
        },
        work_intervals: [
          {
            start_time: "",
            end_time: "",
            workforce: this.getEmptyWorkforce(),
          },
        ],
      });
    },
    removeProcess(idx: number) {
      if (idx !== -1) {
        this.processes.splice(idx, 1);
      }
      this.confirmProcessDelete = false;
    },
    updateProcessLocation(processLocation: {
      maskId: string;
      levelId: string;
      bbox: [number, number][][];
    }) {
      if (this.currentProcess) {
        this.currentProcess.location = processLocation.bbox;
        this.currentProcess.section_mask_mapping.id = processLocation.maskId;
        this.currentProcess.section_mask_mapping.level_id = processLocation.levelId;
      }
    },
    updateWorkIntervalTime(
      process: ReviewProcess,
      idx: number,
      key: "start_time" | "end_time",
      time: string,
    ) {
      this.processes = this.processes.map((item) => {
        if (item._id === process._id) {
          item.work_intervals[idx][key] = this.setMinuteHourForIso(
            time,
            item.work_intervals[idx][key],
          );
        }
        return item;
      });
    },
    jumpToVideoTime(time: string) {
      const videoPlayer = this.$refs.timelapsePlayer as {
        jumpToMilestone: (time: Date) => void;
      };
      if (!videoPlayer) {
        return;
      }

      const timeDate = dateService.parseLocalDate(time);
      videoPlayer.jumpToMilestone(timeDate);
    },
    hasInvalidBbox(process: ReviewProcess) {
      const hasInvalidBbox = process.location.some((location) => location.length !== 4);
      if (hasInvalidBbox) {
        logger.error("invalid bbox for single day validation");
      }
      return hasInvalidBbox;
    },
    validateProcesses(processes: ReviewProcess[]): boolean {
      for (let i = 0; i < processes.length; i++) {
        const process = processes[i];
        if (process.decoded_label === "") {
          return true;
        }
        if (process.location.length === 0 || this.hasInvalidBbox(process)) {
          return true;
        }

        if (!process.section_mask_mapping.level_id) {
          return true;
        }

        const visitedWorkIntervals: ProcessWorkInterval[] = [];
        for (let j = 0; j < process.work_intervals.length; j++) {
          if (
            process.work_intervals[j].start_time === "" ||
            process.work_intervals[j].end_time === ""
          ) {
            return true;
          }
          if (process.work_intervals[j].start_time >= process.work_intervals[j].end_time) {
            return true;
          }
          if (
            this.hasWorkIntervalOverlap(
              visitedWorkIntervals,
              process.work_intervals[j].start_time,
              process.work_intervals[j].end_time,
            )
          ) {
            return true;
          }
          visitedWorkIntervals.push(process.work_intervals[j]);
        }
      }
      return false;
    },
    hasWorkIntervalOverlap(
      workIntervals: ProcessWorkInterval[],
      start_time: string,
      end_time: string,
    ) {
      return workIntervals.some(
        (item) =>
          (start_time >= item.start_time && start_time < item.end_time) ||
          (end_time <= item.end_time && end_time > item.start_time),
      );
    },
    async saveData(stage: ValidationStage = null) {
      this.loadingApproveStage = stage;
      this.validationError = this.validateProcesses(this.processes);
      if (this.validationError) {
        this.loadingApproveStage = null;
        this.showToast("error", "Empty fields!");
        return;
      }

      const endTimeEdit = new Date();
      await this.saveOpsReviewData(
        this.processes,
        stage,
        this.startTimeEdit || new Date(),
        endTimeEdit,
      );

      this.startTimeEdit = null;
      this.loadingApproveStage = null;
    },
    openLocationModal(process: ReviewProcess) {
      this.currentProcess = process;
      this.locationMappingModalOpen = true;
    },
    closeLocationMappingModal() {
      this.locationMappingModalOpen = false;
    },
    handlePeopleCountChange(e: Event, process: ReviewProcess, index: number) {
      const value = (e.target as HTMLInputElement).value;
      process.work_intervals[index].workforce.validated_count = value ? parseInt(value) : null;
    },
    handlePeopleCountKeyPress(event: KeyboardEvent): void {
      if (!event.key.match(/^\d*$/)) {
        event.preventDefault();
      }
    },
    handleReviewStartTime() {
      if (!this.startTimeEdit) {
        this.startTimeEdit = new Date();
      }
    },
    setPipelineProcesses() {
      this.processes = [];
      this.pipelineData.forEach((process) => {
        this.processes.push({
          ...process,
          _id: uuidv4(),
          start_time: this.roundNearest15Minutes(process.start_time),
          end_time: this.roundNearest15Minutes(process.end_time),
          section_mask_mapping: {
            id: null,
            level_id: null,
          },
          work_intervals: process.work_intervals.map((workInterval) => ({
            ...workInterval,
            start_time: this.roundNearest15Minutes(workInterval.start_time),
            end_time: this.roundNearest15Minutes(workInterval.end_time),
          })),
        });
      });
    },
    switchProcessesMode(mode: "latest" | "first_level_review" | "pipeline") {
      this.currentMode = mode;

      if (mode === "latest") {
        this.processes = this.opsReviewProcesses;
      } else if (mode === "first_level_review") {
        this.processes = this.opsReviewModeProcesses;
      } else if (mode === "pipeline") {
        this.setPipelineProcesses();
      }
    },
    highlightWorkInterval(processIndex: number, workIntervalIndex: number) {
      const sortedProcesses = this.processes
        .slice()
        .sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());

      const flattened = sortedProcesses.flatMap((process) => process.work_intervals);
      const originalProcess = this.processes[processIndex];
      const originalInterval = originalProcess.work_intervals[workIntervalIndex];

      this.highlightedWorkingIntervalIndex = flattened.findIndex((item) => {
        return item === originalInterval;
      });
    },
    dropHighlightWorkInterval() {
      this.highlightedWorkingIntervalIndex = undefined;
    },
  },
  setup() {
    const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();
    const processClasses = useProcessClasses();
    const router = useRouter();
    const route = useRoute();
    return {
      router,
      route,
      showSaveBeforeLeaveConfirmationModal,
      processClasses,
    };
  },
});
</script>

<style>
@media only screen and (min-width: 2560px) {
  .resizeModal {
    max-width: 85vw;
  }
}

@media only screen and (max-width: 2559px) {
  .resizeModal {
    max-width: 95vw;
  }
}

.no_data {
  @apply bg-red-400 text-red-900;
}

.ready_to_review {
  @apply bg-yellow-300 text-yellow-900;
}

.reviewed {
  @apply bg-green-200 text-green-900;
}

.reviewed_and_checked {
  @apply bg-purple-200 text-purple-900;
}

.no_data_possible {
  @apply bg-gray-200 text-gray-700;
}

.partially_reviewed {
  @apply bg-orange-300 text-orange-800;
}

.no_section_masks {
  @apply bg-gray-400 text-gray-900;
}

/** Table Data **/
.editInput input,
.editInput textarea {
  @apply rounded-none border-gray-400 focus:border-yellow-500 focus:ring-yellow-500;
}

.editInput input {
  height: 38px;
}

.overviewInput input,
.overviewInput textarea {
  @apply border-0 py-0 pl-0 text-xs shadow-none sm:text-sm;
}
</style>
